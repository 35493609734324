import ModelService from "@/services/ModelService";
import {useAlertStore} from "@/stores";
import {getResponseError} from "@/helpers/api";

import {useGlobalStateStore} from "@/stores";
export default class UserService extends ModelService {

    constructor() {
        super();
        this.url = '/users';
    }

    public updateAvatar(id, payload) {
        const formData = new FormData();
        formData.append("avatar", payload.avatar);
        formData.append('_method', 'put');
        return this.post(`/users/${id}/avatar`, formData);
    }

    public getRoles(){
        return this.get('/roles/search');
    }

    public getPermissions(){
        return this.get('/permissions');
    }

    public assignPermissions(id,payload){
        const alertStore = useAlertStore();
        const globalUserState = useGlobalStateStore();
        globalUserState.setElementLoading(id, true);
        return this.post('/assign-permissions',payload).then((response) => {
            let answer = response.data;
            alertStore.success(answer.message);
        }).catch((error) => {
            alertStore.error(getResponseError(error));
        }).finally(() => {
            globalUserState.setElementLoading(id, false);
        })
        
    } 

}

<template>
  <Auth>
    <div class="m-auto bg-primary w-25 p-10 text-center">
      <button class="relative z-10 w-48 h-48 rounded-full bg-white">
        <img src="/assets/images/logo.png" />
      </button>
      <LoginForm />
    </div>
  </Auth>
</template>

<script>
import { default as LoginForm } from '@/views/pages/auth/login/Form'

import { trans } from '@/helpers/i18n'
import Auth from '@/views/layouts/Auth'

export default {
  name: 'LoginView',
  components: {
    Auth,
    LoginForm,
  },
  setup() {
    return {
      trans,
    }
  },
}
</script>

<template>
  <Page :title="page.title" :breadcrumbs="page.breadcrumbs" :actions="page.actions" @action="onAction"
    class="w-full m-5">
    <Panel>
      <Form id="create-product" @submit.prevent="onSubmit">
        <div class="flex">
          <div class="w-1/2 p-2">
            <TextInput class="mb-4" type="text" :required="true" name="name" v-model="form.name"
              :label="trans('product.labels.name')" />
          </div>
          <div class="w-1/2 p-2">
            <label :for="name" class="text-sm text-gray-500">
              Categoría
              <span class="text-red-600">*</span>
            </label>
            <select
              class="mb-4 block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-theme-500 focus:border-theme-500 text-sm"
              :required="true" name="cate_id" v-model="form.cate_id" :label="trans('product.labels.category')">
              <option :value="null">Seleccione una categoría</option>
              <option v-for="item in page.categories" :value="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="w-1/3 p-2">
            <TextInput class="mb-4" type="text" :required="true" name="sku" v-model="form.sku"
              :label="trans('product.labels.sku')" />
          </div>
        </div>

        <!-- <div class="flex">
          <div class="w-full p-2">
            <TextInput class="mb-4" type="textarea" :required="true" name="description" v-model="form.description"
              :label="trans('product.labels.description')" />
          </div>
        </div> -->

        <div class="flex">
          <div class="w-full p-2">
            <label for="description" class="text-sm text-gray-500">
              {{ trans('product.labels.description') }}
              <span class="text-red-600">*</span>
            </label>
            <div id="quill-editor" style="height: 300px;"></div>
          </div>
        </div>

        <div class="flex">
          <div class="w-1/4 p-2">
            <TextInput class="mb-4" type="text" :required="true" name="original_price" v-model="form.original_price"
              label="Precio" />
          </div>
          <div class="w-1/4 p-2">
            <TextInput class="mb-4" type="text" :required="true" name="selling_price" v-model="form.selling_price"
              label="Precio embalaje" />
          </div>
          <div class="w-1/4 p-2">
            <TextInput class="mb-4" type="text" :required="true" name="min_qty_mayor" v-model="form.min_qty_mayor"
              label="Cantidad mínima" />
          </div>
          <div class="w-1/4 p-2">
            <TextInput class="mb-4" type="text" :required="true" name="min_qty_embalaje" v-model="form.min_qty_embalaje"
              label="Cantidad mínima(Embalaje)" />
          </div>
          <div class="w-1/4 p-2">
            <TextInput class="mb-4" type="text" :required="true" name="qty" v-model="form.qty"
              label="Cantidad(Stock)" />
          </div>
          <div class="w-1/4 p-2">
            <label :for="name" class="text-sm text-gray-500">
              Estado
              <span class="text-red-600">*</span>
            </label>
            <select
              class="mb-4 block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-theme-500 focus:border-theme-500 text-sm"
              :required="true" name="status" v-model="form.status" :label="trans('product.labels.status')">
              <option value="1">Activo</option>
              <option value="0">Desactivado</option>
            </select>
          </div>
        </div>
        <div class="w-full p-2">
          <label for="">Imágen Principal</label>
          <br />
          <br />
          <a @click="$refs.btnAvatar.click()"
            class="my-4 px-4 py-2 border border-transparent text-sm rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 text-center transition text-white bg-theme-600 hover:bg-theme-800 focus:bg-theme-800 focus:ring-theme-800">
            Agregar Imágen Principal <i class="fa fa-image"></i></a>
          <br>
          <div v-if="form.imageSrc" style="border:1px solid #f1f1f1; margin-right:5px" class="w-1/6 p-5 mt-3">
            <img class="img-tumbail" :src="form.imageSrc" alt="" />
          </div>
          <input ref="btnAvatar" type="file" style="display: none" accept="image/*" @change="onFileChange" />
        </div>
        <br>
        <hr>
        <div class="w-full p-2">
          <label for="">Imágenes Adicionales</label>
          <br />
          <br />
          <a @click="$refs.btnImages.click()"
            class="px-4 py-2 border border-transparent text-sm rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 text-center transition text-white bg-theme-600 hover:bg-theme-800 focus:bg-theme-800 focus:ring-theme-800">
            Agregar Imágenes
            <i class="fa fa-image"></i>
          </a>
          <input type="file" style="display: none;" multiple="true" ref="btnImages" accept="image/*"
            @change="onFileChangeMultiple" />
          <div class="flex">
            <div style="
                position: relative;
                border: 1px solid #f1f1f1;
                margin-right: 5px;
              " v-for="item in form.imagesSrc" class="w-1/6 p-5 mt-3">
              <img class="img-tumbail" :src="item.imageSrc" alt="" />
              <a @click="deleteImage(item)" style="position: absolute; bottom: 0; right: 0;"
                class="uppercase cursor-pointer text-lg mr-3 text-danger-400" title="Delete">
                <i class="fa fa-trash"></i>
              </a>
            </div>
          </div>
        </div>
      </Form>
    </Panel>
  </Page>
</template>

<script>
import { defineComponent, reactive, onMounted, ref } from 'vue'
import { trans } from '@/helpers/i18n'
import { useAuthStore } from '@/stores/auth'
import Button from '@/views/components/input/Button'
import TextInput from '@/views/components/input/TextInput'
import Dropdown from '@/views/components/input/Dropdown'
import Alert from '@/views/components/Alert'
import Panel from '@/views/components/Panel'
import Page from '@/views/layouts/Page'
import Modal from "@/views/components/Modal";
import FileInput from '@/views/components/input/FileInput'
import ProductService from '@/services/ProductService'
import CategoryService from '@/services/CategoryService'
import { clearObject, reduceProperties } from '@/helpers/data'
import { toUrl } from '@/helpers/routing'
import Form from '@/views/components/Form'
import { useAlertStore } from '@/stores'
import { getResponseError } from '@/helpers/api'

import Quill from 'quill';
import 'quill/dist/quill.snow.css';

export default defineComponent({
  components: {
    Form,
    FileInput,
    Panel,
    Alert,
    Dropdown,
    TextInput,
    Button,
    Page,
    Modal
  },
  setup() {
    const { user } = useAuthStore()

    const form = reactive({
      name: '',
      slug: '',
      small_description: '',
      description: '',
      cate_id: null,
      image: null,
      imageSrc: null,
      images: [],
      imagesSrc: [],
      status: '1',
      sku: '',
      qty: '',
      original_price: '',
      selling_price: '',
      tax: '',
    })

    const formVariation = reactive({
      variation: null,
      price: '',
    })

    const formNewVariation = reactive({
      name: null,
    })

    const page = reactive({
      id: 'create_product',
      title: trans('global.pages.product_create'),
      filters: false,
      breadcrumbs: [
        {
          name: trans('global.pages.products'),
          to: toUrl('/product/list'),
        },
        {
          name: trans('global.pages.product_create'),
          active: true,
        },
      ],
      actions: [
        {
          id: 'back',
          name: trans('global.buttons.back'),
          icon: 'fa fa-angle-left',
          to: toUrl('/product/list'),
          theme: 'outline',
        },
        {
          id: 'submit',
          name: trans('global.buttons.save'),
          icon: 'fa fa-save',
          type: 'submit',
        },
      ],
      variations: [],
      recordsVariations: [],
      categories: [],
      isModalCreatevariationShowing: false
    })

    const service = new ProductService()
    const categoryService = new CategoryService()

    function onAction(data) {
      switch (data.action.id) {
        case 'submit':
          onSubmit()
          break
      }
    }

    function getCategories() {
      categoryService
        .index()
        .then((response) => {
          page.categories = response.data.data
        })
        .catch((error) => {
          alertStore.error(getResponseError(error))
          table.loading = false
        })
    }

    function getVariations() {
      service
        .getVariations()
        .then((response) => {
          page.variations = response.data.data
        })
        .catch((error) => {
          console.log('getResponseError(error)', getResponseError(error))
          alertStore.error(getResponseError(error))
          table.loading = false
        })
    }

    function addVariation() {
      let row = {
        variation: {
          variation_id: this.formVariation.variation.id,
          variation_name: this.formVariation.variation.name,
          variation_description: this.formVariation.variation_description,
        },
        price: this.formVariation.price,
      }
      this.page.recordsVariations.push(row)
    }

    function deleteRecord(item) {
      let idx = this.page.recordsVariations.indexOf(item)
      this.page.recordsVariations.splice(idx, 1)
    }

    function onFileChange(e) {
      const file = e.target.files[0]
      let url = URL.createObjectURL(file)
      form.image = file,
        form.imageSrc = url
    }
    function onFileChangeMultiple(e) {
      console.log('dfsds')
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i]
        let url = URL.createObjectURL(file)
        let item = { image: file, imageSrc: url }
        form.images.push(file)
        form.imagesSrc.push({ imageSrc: url })
      }
      console.log('form.images', form.images)
    }

    function onSubmit() {
      form.variations = JSON.stringify(page.recordsVariations);
      console.log("form.images", form.images)
      service
        .handleCreate('create-product', reduceProperties(form, 'id'))
        .then(() => {
          //clearObject(form)
        })
      return false
    }

    function deleteImage(item) {
      let idx = this.form.imagesSrc.indexOf(item)
      this.form.imagesSrc.splice(idx, 1)
      this.form.images.splice(idx, 1)
    }

    const statusList = [
      { id: 0, title: 'Active' },
      { id: 1, title: 'Inactive' },
    ]
    function newVariation() {
      page.isModalCreatevariationShowing = true;
    }

    function onSubmitVariation() {
      service
        .createNewVariation(reduceProperties(formNewVariation))
        .then(() => {
          clearObject(formNewVariation);
          getVariations();
          page.isModalCreatevariationShowing = false;
        })
      return false
    }

    const quillEditor = ref(null); // Referencia al editor

    onMounted(() => {

      // Inicializa Quill cuando el componente se monta
      quillEditor.value = new Quill('#quill-editor', {
        theme: 'snow',
        placeholder: 'Escribe la descripción del producto...',
        modules: {
          toolbar: [
            // [{ header: [1, 2, false] }],
            [{ 'font': [] }], // Agregar selección de fuente
            [{ 'size': ['small', false, 'large', 'huge'] }], // Agregar selección de tamaño de fuente          
            ['bold', 'italic', 'underline'],
            [{ 'align': [] }],                    // Alineación (izquierda, centro, derecha, justificado)
            [{ 'color': [] }, { 'background': [] }] // 
            // ['image', 'code-block']
          ]
        }
      });

      // Si quieres establecer el valor inicial desde form.description
      if (form.description) {
        quillEditor.value.root.innerHTML = form.description;
      }

      // Sincroniza el contenido del editor con form.description
      quillEditor.value.on('text-change', () => {
        form.description = quillEditor.value.root.innerHTML;
      });

      getVariations(), getCategories()
    })

    return {
      trans,
      user,
      form,
      page,
      onSubmit,
      onAction,
      statusList,
      addVariation,
      deleteRecord,
      formVariation,
      getVariations,
      onFileChange,
      onFileChangeMultiple,
      deleteImage,
      newVariation,
      formNewVariation,
      onSubmitVariation,

      quillEditor,

    }
  },
})
</script>

<style scoped></style>

<template>
  <div
    class="bg-gray-100 flex"
    v-if="authStore.user && authStore.user.hasOwnProperty('id')"
  >
    <div class="relative w-full flex flex-col h-screen overflow-y-hidden">
      <!-- Desktop Header -->
      <header class="w-full items-center bg-white py-2 px-6 hidden sm:flex">
        <div class="relative w-1/2 flex justify-start">
          <a
            class="flex cursor-pointer focus:outline-none align-middle"
            href="/panel/dashboard"
          >
            <button
              class="relative z-10 w-24 h-24 rounded-full overflow-hidden border-4 border-gray-400 hover:border-gray-300 focus:border-gray-300 focus:outline-none"
            >
              <img src="/assets/images/logo.png" />
            </button>
          </a>
        </div>
        <div class="w-1/3 flex justify-center">
          <a
            class="flex cursor-pointer focus:outline-none align-middle"
            @click="state.isSubMenuDropdownOpen = !state.isSubMenuDropdownOpen"
          >
            <span class="text-lg px-1 py-2 text-red-600">
              {{ trans('global.pages.mantenimiento') }}
              <Icon
                :name="state.isSubMenuDropdownOpen ? 'angle-up' : 'angle-down'"
              />
            </span>
          </a>
          <button
            v-if="state.isSubMenuDropdownOpen"
            @click="state.isSubMenuDropdownOpen = false"
            class="text-red-600 h-full w-full fixed inset-0 cursor-pointer"
          ></button>
          <div
            v-if="state.isSubMenuDropdownOpen"
            class="absolute w-32 bg-white rounded-lg shadow-lg py-2 mt-16 z-50"
          >
            <router-link
            @click="state.isSubMenuDropdownOpen = !state.isSubMenuDropdownOpen"
              to="/panel/category"
              class="block text-red-600 px-4 py-2 hover:bg-theme-800 hover:text-white hover:opacity-80"
            >
              Categorías
            </router-link>
            <router-link
            @click="state.isSubMenuDropdownOpen = !state.isSubMenuDropdownOpen"
              to="/panel/product"
              class="block text-red-600 px-4 py-2 hover:bg-theme-800 hover:text-white hover:opacity-80"
            >
              Productos
            </router-link>
            <router-link
            @click="state.isSubMenuDropdownOpen = !state.isSubMenuDropdownOpen"
              to="/panel/customer"
              class="block text-red-600 px-4 py-2 hover:bg-theme-800 hover:text-white hover:opacity-80"
            >
              Clientes
            </router-link>
            <router-link
            @click="state.isSubMenuDropdownOpen = !state.isSubMenuDropdownOpen"
              to="/panel/supplier"
              class="block text-red-600 px-4 py-2 hover:bg-theme-800 hover:text-white hover:opacity-80"
            >
              Proveedores
            </router-link>
            <router-link
            @click="state.isSubMenuDropdownOpen = !state.isSubMenuDropdownOpen"
              to="/panel/banner"
              class="block text-red-600 px-4 py-2 hover:bg-theme-800 hover:text-white hover:opacity-80"
            >
              Banners
            </router-link>

            <router-link
            @click="state.isSubMenuDropdownOpen = !state.isSubMenuDropdownOpen"
              to="/panel/coupon"
              class="block text-red-600 px-4 py-2 hover:bg-theme-800 hover:text-white hover:opacity-80"
            >
              Cupones
            </router-link>
            
            <router-link
            @click="state.isSubMenuDropdownOpen = !state.isSubMenuDropdownOpen"
              to="/panel/users"
              class="block text-red-600 px-4 py-2 hover:bg-theme-800 hover:text-white hover:opacity-80"
            >
              Usuarios
            </router-link>
            <router-link
            @click="state.isSubMenuDropdownOpen = !state.isSubMenuDropdownOpen"
              to="/panel/roles"
              class="block text-red-600 px-4 py-2 hover:bg-theme-800 hover:text-white hover:opacity-80"
            >
              Roles
            </router-link>
          </div>
        </div>
        <div class="w-1/3 flex justify-center">
          <router-link
            to="/panel/shopping"
            class="block text-lg text-red-600 px-1 py-2 hover:bg-theme-800 hover:text-white hover:opacity-80"
          >
            {{ trans('global.pages.compras') }}
          </router-link>
        </div>
        <div class="w-1/3 flex justify-center">
          <router-link
            to="/panel/stock"
            class="block text-lg text-red-600 px-1 py-2 hover:bg-theme-800 hover:text-white hover:opacity-80"
          >
            {{ trans('global.pages.existencias') }}
          </router-link>
        </div>
        
        <div class="w-1/3 flex justify-center">
          <router-link
            to="/panel/order"
            class="block text-lg text-red-600 px-1 py-2 hover:bg-theme-800 hover:text-white hover:opacity-80"
          >
            {{ trans('global.pages.pedidos') }}
          </router-link>
        </div>
        <div class="w-1/3 flex justify-center">
          <router-link
            to="/panel/sales"
            class="block text-lg text-red-600 px-1 py-2 hover:bg-theme-800 hover:text-white hover:opacity-80"
          >
            {{ trans('global.pages.orden_de_venta') }}
          </router-link>
        </div>
        <div class="w-1/3 flex justify-center">
          <a
            class="flex cursor-pointer focus:outline-none align-middle"
            @click="state.isSubMenuReportDropdownOpen = !state.isSubMenuReportDropdownOpen"
          >
            <span class="text-lg px-1 py-2 text-red-600">
              Reportes
              <Icon
                :name="state.isSubMenuReportDropdownOpen ? 'angle-up' : 'angle-down'"
              />
            </span>
          </a>
          <button
            v-if="state.isSubMenuReportDropdownOpen"
            @click="state.isSubMenuReportDropdownOpen = false"
            class="text-red-600 h-full w-full fixed inset-0 cursor-pointer"
          ></button>
          <div
            v-if="state.isSubMenuReportDropdownOpen"
            class="absolute w-32 bg-white rounded-lg shadow-lg py-2 mt-16 z-50"
          >
            <router-link
            @click="state.isSubMenuReportDropdownOpen = !state.isSubMenuReportDropdownOpen"
              to="/panel/report/product"
              class="block text-red-600 px-4 py-2 hover:bg-theme-800 hover:text-white hover:opacity-80"
            >
              ventas por producto
            </router-link>
            <router-link
            @click="state.isSubMenuReportDropdownOpen = !state.isSubMenuReportDropdownOpen"
              to="/panel/report/customer"
              class="block text-red-600 px-4 py-2 hover:bg-theme-800 hover:text-white hover:opacity-80"
            >
              ventas por cliente
            </router-link>
            <!-- <router-link
            @click="state.isSubMenuReportDropdownOpen = !state.isSubMenuReportDropdownOpen"
              to="/panel/report/region"
              class="block text-red-600 px-4 py-2 hover:bg-theme-800 hover:text-white hover:opacity-80"
            >
              ventas por región
            </router-link> -->
          </div>
        </div>
        <div class="relative w-1/2 flex justify-end">
          <a
            class="flex cursor-pointer focus:outline-none align-middle"
            @click="state.isAccountDropdownOpen = !state.isAccountDropdownOpen"
          >
            <span class="relative pt-3 mr-2 text-red-600">
              {{ authStore.user.full_name }}
              <Icon
                :name="state.isAccountDropdownOpen ? 'angle-up' : 'angle-down'"
              />
            </span>
            <button
              class="relative z-10 w-12 h-12 rounded-full overflow-hidden border-4 border-gray-400 hover:border-gray-300 focus:border-gray-300 focus:outline-none"
            >
              <img
                :alt="authStore.user.full_name"
                v-if="authStore.user.avatar_url"
                :src="authStore.user.avatar_url"
              />
              <AvatarIcon v-else />
            </button>
          </a>

          <button
            v-if="state.isAccountDropdownOpen"
            @click="state.isAccountDropdownOpen = false"
            class="text-red-600 h-full w-full fixed inset-0 cursor-pointer"
          ></button>
          <div
            v-if="state.isAccountDropdownOpen"
            class="absolute w-32 bg-white rounded-lg shadow-lg py-2 mt-16 z-50"
          >
            <router-link
              to="/panel/profile"
              class="block text-red-600 px-4 py-2 hover:bg-theme-800 hover:text-white hover:opacity-80"
            >
              {{ trans('global.pages.profile') }}
            </router-link>
            <a
              href="#"
              @click.prevent="onLogout"
              class="block text-red-600 px-4 py-2 hover:bg-theme-800 hover:text-white hover:opacity-80"
            >
              {{ trans('global.phrases.sign_out') }}
            </a>
          </div>
        </div>
      </header>

      <!-- Mobile Header & Nav -->
      <header class="w-full bg-theme-600 py-5 px-6 sm:hidden">
        <div class="flex items-center justify-between">
          <router-link
            class="text-white text-3xl font-semibold uppercase hover:text-gray-300"
            to="/panel/dashboard"
          >
            Ale Store
          </router-link>
          <button
            @click="state.isMobileMenuOpen = !state.isMobileMenuOpen"
            class="text-white text-3xl focus:outline-none"
          >
            <i v-if="!state.isMobileMenuOpen" class="fa fa-bars"></i>
            <i v-else class="fa fa-times"></i>
          </button>
        </div>
        <nav
          :class="state.isMobileMenuOpen ? 'flex' : 'hidden'"
          class="flex flex-col pt-4 text-base text-white"
        >
          <Menu :state="state" :type="'mobile'" />
        </nav>
      </header>

      <div class="w-full h-screen overflow-x-hidden border-t flex flex-col">
        <main class="w-full flex-grow p-6">
          <div
            class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow h-full py-5 px-9"
          >
            
              <router-view />
           
        </div>
        </main>
        <footer
          class="w-full bg-white text-center text-sm p-4"
          v-html="trans('global.phrases.copyright')"
        ></footer>
      </div>
    </div>
  </div>
  <template v-else>
    <router-view />
  </template>
</template>

<script>
import { computed, onBeforeMount, reactive } from 'vue'

import { trans } from '@/helpers/i18n'
import Menu from '@/views/layouts/Menu'
import Icon from '@/views/components/icons/Icon'
import AvatarIcon from '@/views/components/icons/Avatar'
import { useAuthStore } from '@/stores/auth'
import { useGlobalStateStore } from '@/stores'
import { useRoute } from 'vue-router'
import { useAlertStore } from '@/stores'
import { getAbilitiesForRoute } from '@/helpers/routing'

export default {
  name: 'app',
  components: {
    AvatarIcon,
    Menu,
    Icon,
  },
  setup() {
    const alertStore = useAlertStore()
    const authStore = useAuthStore()
    const globalStateStore = useGlobalStateStore()
    const route = useRoute()

    const isLoading = computed(() => {
      var value = false
      for (var i in globalStateStore.loadingElements) {
        if (globalStateStore.loadingElements[i]) {
          value = true
          break
        }
      }
      return value || globalStateStore.isUILoading
    })

    const state = reactive({
      mainMenu: [
        {
          name: trans('global.pages.home'),
          icon: 'tachometer',
          showDesktop: true,
          showMobile: true,
          requiresAbility: false,
          to: '/panel/dashboard',
        },
        {
          name: trans('global.pages.users'),
          icon: 'users',
          showDesktop: true,
          showMobile: true,
          requiresAbility: getAbilitiesForRoute([
            'users.list',
            'users.create',
            'users.edit',
          ]),
          to: '/panel/users/list',
          children: [
            {
              name: trans('global.phrases.all_records'),
              icon: '',
              showDesktop: true,
              showMobile: true,
              requiresAbility: getAbilitiesForRoute('users.list'),
              to: '/panel/users/list',
            },
            {
              name: trans('global.buttons.add_new'),
              icon: '',
              showDesktop: true,
              showMobile: true,
              requiresAbility: getAbilitiesForRoute('users.create'),
              to: '/panel/users/create',
            },
          ],
        },
        {
          name: trans('global.phrases.sign_out'),
          icon: 'sign-out',
          showDesktop: false,
          showMobile: true,
          showIfRole: false,
          onClick: onLogout,
          to: '',
        },
      ],
      headerLeftLink: {
        name: trans('global.buttons.new_record'),
        icon: 'plus',
        to: '',
        href: '#',
      },
      footerLeftLink: {
        name: trans('global.buttons.documentation'),
        icon: 'paperclip',
        to: '',
        href: '#',
      },
      isSubMenuDropdownOpen: false,
      isSubMenuReportDropdownOpen:false,
      isAccountDropdownOpen: false,
      isMobileMenuOpen: false,
      currentExpandedMenuItem: null,
      app: window.AppConfig,
    })

    function onLogout() {
      authStore.logout()
    }

    onBeforeMount(() => {
      if (route.query.hasOwnProperty('verified') && route.query.verified) {
        alertStore.success(trans('global.phrases.email_verified'))
      }
    })

    return {
      state,
      authStore,
      globalStateStore,
      trans,
      onLogout,
      isLoading,
    }
  },
}
</script>

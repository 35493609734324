import ModelService from "@/services/ModelService";

export default class ReporteService extends ModelService {

    constructor() {
        super();
        this.url = '/reporte';
    }

    
    public ventasProducto(params){
        
        return this.get(`reporte/ventas-producto${params}`);
    }
    public ventasCliente(params){
        return this.get(`reporte/ventas-cliente${params}`);
        
    }
}

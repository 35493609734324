import ModelService from "@/services/ModelService";
import {useAlertStore} from "@/stores";
import {getResponseError} from "@/helpers/api";

import {useGlobalStateStore} from "@/stores";
export default class OrderService extends ModelService {

    constructor() {
        super();
        this.url = '/order';
    }
    public getComunas(){
        return this.get(`orders/comunas`);
    }
    public getBancos(){
        return this.get(`orders/bancos`);
    }

    public updateStatus(payload){
        let data={data:payload};
        const alertStore = useAlertStore();
        const globalUserState = useGlobalStateStore();
        globalUserState.setElementLoading(true);                            
        return this.post(`/orders/updateStatus`, data).then((response) => {
            let answer = response.data;
            alertStore.success(answer.message);
        }).catch((error) => {
            alertStore.error(getResponseError(error));
        }).finally(() => {
            globalUserState.setElementLoading(false);
        })
        
    } 
    public fusionarPedido(payload){
        let data={data:payload};
        const alertStore = useAlertStore();
        const globalUserState = useGlobalStateStore();
        globalUserState.setElementLoading(true);                            
        return this.post(`/orders/fusionarPedido`, data).then((response) => {
            let answer = response.data;
            alertStore.success(answer.message);
        }).catch((error) => {
            alertStore.error(getResponseError(error));
        }).finally(() => {
            globalUserState.setElementLoading(false);
        })
        
    } 

    public emitirOrdenVenta(orderId){
        let data={};
        const alertStore = useAlertStore();
        const globalUserState = useGlobalStateStore();
        globalUserState.setElementLoading(true);                            
        return this.post(`/orders/emitirOrdenVenta/${orderId}`, data).then((response) => {
            let answer = response.data;
            alertStore.success(answer.message);
        }).catch((error) => {
            alertStore.error(getResponseError(error));
        }).finally(() => {
            globalUserState.setElementLoading(false);
        })
        
    } 

    
    


}

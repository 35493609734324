import ModelService from "@/services/ModelService";

export default class ShoppingService extends ModelService {

    constructor() {
        super();
        this.url = '/shopping';
    }

}

import ModelService from "@/services/ModelService";

export default class SupplierService extends ModelService {

    constructor() {
        super();
        this.url = '/supplier';
    }

}

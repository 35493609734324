<template>
  <Page
    :title="page.title"
    :breadcrumbs="page.breadcrumbs"
    :actions="page.actions"
    @action="onPageAction"
    class="w-full m-5"
  >
    <template #filters v-if="page.toggleFilters">
      <Filters @clear="onFiltersClear">
        <FiltersRow>
          <FiltersCol>
            <TextInput name="lname" :label="trans('order.labels.lname')" v-model="mainQuery.filters.lname.value"></TextInput>
          </FiltersCol>
          <FiltersCol>
            <div>
              <label :for="name" class="text-sm text-gray-500">
                Estado de pedido
              </label>
              <select
                class="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-theme-500 focus:border-theme-500 text-sm"
                :required="true"
                name="payment_status"
                v-model="mainQuery.filters.status.value"
                :label="trans('order.labels.status')"
              >
                <option value="">Estado de pedido</option>
                <option value="0">RECIBIDO</option>
                <option value="1">EN PREPARACIÓN</option>
                <option value="2">ENVIADO</option>
              </select>
            </div>
          </FiltersCol>
          <FiltersCol>
            <div>
              <label :for="name" class="text-sm text-gray-500">
                Estado de pago
              </label>
              <select
                class="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-theme-500 focus:border-theme-500 text-sm"
                :required="true"
                name="payment_status"
                v-model="mainQuery.filters.payment_status.value"
                :label="trans('order.labels.payment_status')"
              >
                <option value="">Estado de pago</option>
                <option value="0">RECHAZADO</option>
                <option value="1">PENDIENTE</option>
                <option value="2">PAGADO</option>
              </select>
            </div>
          </FiltersCol>
        </FiltersRow>
      </Filters>
    </template>

    <template #default>
      <Modal
        :is-showing="page.isModalUpdateStatus"
        @close="page.isModalUpdateStatus = false"
      >
        <Panel>
          <Form id="create-variation" @submit.prevent="updateStatus">
            <div class="w-full p-2">
              <label :for="name" class="text-sm text-gray-500">
                Estado de pedido
              </label>
              <select
                class="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-theme-500 focus:border-theme-500 text-sm"
                :required="true"
                name="payment_status"
                v-model="page.order_status"
                :label="trans('order.labels.status')"
              >
                <option value="">Estado de pedido</option>
                <option value="0">RECIBIDO</option>
                <option value="1">EN PREPARACIÓN</option>
                <option value="2">ENVIADO</option>
              </select>
            </div>
            <div class="w-full text-right">
              <button
                type="submit"
                class="px-4 py-2 border border-transparent text-sm rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 text-center transition text-white bg-theme-600 hover:bg-theme-800 focus:bg-theme-800 focus:ring-theme-800"
                title="Guardar"
              >
                <i class="mr-1 fa fa-save"></i>
                Guardar
              </button>
            </div>
          </Form>
        </Panel>
      </Modal>
      <Modal
        :is-showing="page.isModalFusionarPedido"
        @close="page.isModalFusionarPedido = false"
      >
        <Panel>
          <div v-if="page.validadoFusionarPedido">
            <p class="text-danger">Los pedidos se fusionaron correctamente</p>
          </div>
          <div v-else>
            <p class="text-danger">
              Solo se pueden fusionar pedidos de un mismo cliente
            </p>
          </div>
        </Panel>
      </Modal>
      <Table
        :id="page.id"
        v-if="table"
        :headers="table.headers"
        :sorting="table.sorting"
        :actions="table.actions"
        :records="table.records"
        :pagination="table.pagination"
        :is-loading="table.loading"
        @page-changed="onTablePageChange"
        @action="onTableAction"
        @sort="onTableSort"
      >
      <template v-slot:content-selected="props">
          <div class="flex items-center">
            <input
              class="mr-5"
              type="checkbox"
              name="rowSelcted"
              id=""
              v-model="props.item.selected"
            />
          </div>
        </template>
      <template v-slot:content-document_number="props">
         <a :href="'/panel/sales/'+props.item.id+'/show'">{{ props.item.document_number }}</a>
        </template>
        <template v-slot:content-status="props">
          <span
            v-if="props.item.status == 0"
            class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
          >
            RECIBIDO
          </span>
          <span
            v-if="props.item.status == 1"
            class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
          >
            EN PREPARACION
          </span>
          <span
            v-if="props.item.status == 2"
            class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
          >
            ENVIADO
          </span>
        </template>
        <template v-slot:content-payment_status="props">
          <span
            v-if="props.item.payment_status == 0"
            class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
          >
            RECHAZAO
          </span>
          <span
            v-if="props.item.payment_status == 1"
            class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
          >
            PENDIENTE
          </span>
          <span
            v-if="props.item.payment_status == 2"
            class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
          >
            PAGADO
          </span>
        </template>
        <template v-slot:content-role="props">
          {{
            props.item.roles
              .map((entry) => {
                return entry.title
              })
              .join(', ')
          }}
        </template>
      </Table>
    </template>
  </Page>
</template>

<script>
import { trans } from '@/helpers/i18n'
import SaleService from '@/services/SaleService'
import { watch, onMounted, defineComponent, reactive, ref } from 'vue'
import { getResponseError, prepareQuery } from '@/helpers/api'
import { toUrl } from '@/helpers/routing'
import { useAlertStore } from '@/stores'
import alertHelpers from '@/helpers/alert'
import Page from '@/views/layouts/Page'
import Panel from '@/views/components/Panel'
import Table from '@/views/components/Table'
import Avatar from '@/views/components/icons/Avatar'
import Filters from '@/views/components/filters/Filters'
import FiltersRow from '@/views/components/filters/FiltersRow'
import FiltersCol from '@/views/components/filters/FiltersCol'
import TextInput from '@/views/components/input/TextInput'
import Dropdown from '@/views/components/input/Dropdown'
import Modal from '@/views/components/Modal'

export default defineComponent({
  components: {
    Dropdown,
    TextInput,
    FiltersCol,
    FiltersRow,
    Filters,
    Page,
    Panel,
    Table,
    Avatar,
    Modal,
  },
  setup() {
    const service = new SaleService()
    const alertStore = useAlertStore()
    const mainQuery = reactive({
      page: 1,
      search: '',
      sort: 'false',
      filters: {
        payment_status: {
          value: '',
          comparison: '=',
        },
        status: {
          value: '',
          comparison: '=',
        },
        lname: {
          value: '',
          comparison: '=',
        },
      },
    })

    const page = reactive({
      id: 'list_users',
      title: 'Órdenes de venta',
      breadcrumbs: [
        {
          name: 'Órdenes de venta',
          to: toUrl('/sales'),
          active: true,
        },
      ],
      isModalUpdateStatus: false,
      isModalFusionarPedido: false,
      actions: [
        {
          id: 'filters',
          name: trans('global.buttons.filters'),
          icon: 'fa fa-filter',
          theme: 'outline',
        },
        {
          id: 'update_status',
          name: 'Actualizar estado de pedido',
          icon: 'fa fa-refresh',
        },
      ],
      toggleFilters: true,
      order_status: '',
      validadoFusionarPedido: false,
    })

    const table = reactive({
      headers: {
        selected: '',
        id: trans('order.labels.id'),
        document_number: trans('order.labels.document_number'),
        status: trans('order.labels.status'),
        lname: trans('order.labels.lname'),
        payment_status: trans('order.labels.payment_status'),
        customer_id: trans('order.labels.customer_id'),
        phoneno: trans('order.labels.phoneno'),
        total_price: trans('order.labels.total'),
        order_date: trans('order.labels.order_date'),
        order_time: trans('order.labels.order_time'),
      },
      pagination: {
        meta: null,
        links: null,
      },
      actions: {
        show: {
          id: 'edit',
          name: trans('global.actions.show'),
          icon: 'fa fa-eye',
          showName: false,
          to: toUrl('/sales/{id}/show'),
        },
        print: {
          id: 'print',
          name: trans('global.actions.print'),
          icon: 'fa fa-print',
          showName: false,
        },
        delete: {
          id: 'delete',
          name: trans('global.actions.delete'),
          icon: 'fa fa-trash',
          showName: false,
          danger: true,
        },
      },
      loading: false,
      records: null,
    })

    function onTableSort(params) {
      mainQuery.sort = params
    }

    function onTablePageChange(page) {
      mainQuery.page = page
    }

    function onTableAction(params) {
      switch (params.action.id) {
        case 'delete':
          alertHelpers.confirmDanger(function () {
            service.delete(params.item.id).then(function (response) {
              fetchPage(mainQuery)
            })
          })
          break
      }
    }
    
    function onPageAction(params) {
      switch (params.action.id) {
        case 'filters':
          page.toggleFilters = !page.toggleFilters
          break
      }
    }

    function onFiltersClear() {
      let clonedFilters = mainQuery.filters
      for (let key in clonedFilters) {
        clonedFilters[key].value = ''
      }
      mainQuery.filters = clonedFilters
    }

    function fetchPage(params) {
      table.records = []
      table.loading = true
      let query = prepareQuery(params)
      service
        .index(query)
        .then((response) => {
          table.records = response.data.data
          table.pagination.meta = response.data.meta
          table.pagination.links = response.data.links
          table.loading = false
        })
        .catch((error) => {
          alertStore.error(getResponseError(error))
          table.loading = false
        })
    }

    watch(mainQuery, (newTableState) => {
      fetchPage(mainQuery)
    })

    function openModalChangeStatus() {
      let rows = table.records.filter((item) => item.selected == true)
      let rowsSelectedCount = rows.length
      if (rowsSelectedCount > 0) {
        page.isModalUpdateStatus = true
      }
    }
    function openModalFusionarPedido() {
      let rows = table.records.filter((item) => item.selected == true)
      let customerIdValidate = false

      let rowsSelectedCount = rows.length
      if (rowsSelectedCount > 0) {
        page.isModalFusionarPedido = true
        let customerId = rows[0].customer_id
        let customerIdInvalid = rows.filter(
          (item) => item.customer_id != customerId,
        ).length
        if (customerIdInvalid == 0) {
          page.validadoFusionarPedido = true
          fusionarPedido()
        } else {
          page.validadoFusionarPedido = false
        }
      }
    }

    function updateStatus() {
      let data = []
      let rows = table.records.filter((item) => item.selected == true)
      let rowsSelectedCount = rows.length
      if (rowsSelectedCount > 0) {
        rows.map((item) => {
          data.push({ id: item.id, status: page.order_status })
        })
        service.updateStatus(data)
        page.isModalUpdateStatus = false
        fetchPage(mainQuery)
      }
    }

    function getProducts(){
      
    }

    function fusionarPedido() {
      let data = []
      let rows = table.records.filter((item) => item.selected == true)
      let rowsSelectedCount = rows.length
      if (rowsSelectedCount > 0) {
        rows.map((item) => {
          data.push({ id: item.id })
        })
        service.fusionarPedido(data)
        page.isModalFusionarPedido = false
        fetchPage(mainQuery)
      }
    }

    onMounted(() => {
      fetchPage(mainQuery)
      document
        .getElementById('update_status')
        .addEventListener('click', function () {
            openModalChangeStatus();
        })
    })

    return {
      trans,
      page,
      table,
      onTablePageChange,
      onTableAction,
      onTableSort,
      onPageAction,
      onFiltersClear,
      openModalChangeStatus,
      openModalFusionarPedido,
      mainQuery,
      updateStatus,
    }
  },
})
</script>

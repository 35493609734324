<template>
  <Page
    :title="page.title"
    :breadcrumbs="page.breadcrumbs"
    :actions="page.actions"
    @action="onAction"
    class="w-full m-5"
  >
    <Panel>
      <Form id="create-shopping" @submit.prevent="onSubmit">
        <div class="flex">
          <div class="w-1/3 p-2">
            <label :for="name" class="text-sm text-gray-500">
              Proveedor
              <span class="text-red-600">*</span>
            </label>
            <select
              class="mb-4 block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-theme-500 focus:border-theme-500 text-sm"
              :required="true"
              name="supplier_id"
              v-model="form.supplier_id"
              :label="trans('shopping.labels.supplier')"
            >
              <option :value="null">Seleccione un proveedor</option>
              <option v-for="item in page.suppliers" :value="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="w-1/4 p-2">
            <TextInput
              class="mb-4"
              type="text"
              :required="true"
              name="document_number"
              v-model="form.document_number"
              :label="trans('shopping.labels.document_number')"
            />
          </div>
          <div class="w-1/4 p-2">
            <TextInput
              class="mb-4"
              type="date"
              :required="true"
              name="shopping_date"
              v-model="form.shopping_date"
              :label="trans('shopping.labels.shopping_date')"
            />
          </div>
          <div class="w-1/4 p-2">
            <TextInput
              class="mb-4"
              type="text"
              :required="true"
              name="total"
              :disabled="true"
              v-model="form.total"
              :label="trans('shopping.labels.total')"
            />
          </div>
          <div class="w-1/4 p-2">
            <label :for="name" class="text-sm text-gray-500">
              Estado
              <span class="text-red-600">*</span>
            </label>
            <select
              class="mb-4 block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-theme-500 focus:border-theme-500 text-sm"
              :required="true"
              name="status"
              v-model="form.status"
              :label="trans('shopping.labels.status')"
            >
              <option value="1">Activo</option>
              <option value="0">Desactivado</option>
            </select>
          </div>
        </div>
        <div class="flex">
          <div class="w-full p-2">
            <TextInput
              class="mb-4"
              type="textarea"
              :required="true"
              name="description"
              v-model="form.description"
              :label="trans('shopping.labels.description')"
            />
          </div>
        </div>

        <Panel>
          <h3>Detalle</h3>
          <br />
          <div class="flex">
            <div class="w-1/3 p-2">
              <label :for="name" class="text-sm text-gray-500">
                Producto
                <span class="text-red-600">*</span>
              </label>
              <a
                href="#"
                @click="page.isModalCreateProductShowing = true"
                class="text-primary"
                style="float: right;"
              >
                <i class="mr-1 fa fa-plus"></i>
              </a>
              <model-select
                :options="page.products"
                v-model="formDetail.product"
                placeholder="Seleccione un producto"
              ></model-select>
            </div>
            <div class="w-1/3 p-2">
              <TextInput
                class="mb-4"
                type="text"
                :required="false"
                name="brand"
                v-model="formDetail.brand"
                :label="trans('shopping.labels.brand')"
              />
            </div>

            <div class="w-1/6 p-2">
              <label class="text-sm text-gray-500">
                {{ trans('shopping.labels.qty') }}
                <span class="text-red-600">*</span>
              </label>

              <input
                type="text"
                :required="true"
                name="qty"
                @input="calculateSubTotal()"
                v-model="formDetail.qty"
                class="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-theme-500 focus:border-theme-500 text-sm"
              />
            </div>
            <div class="w-1/6 p-2">
              <label class="text-sm text-gray-500">
                {{ trans('shopping.labels.unit_price') }}
                <span class="text-red-600">*</span>
              </label>
              <input
                type="text"
                :required="true"
                name="unit_price"
                @input="calculateSubTotal()"
                v-model="formDetail.unit_price"
                class="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-theme-500 focus:border-theme-500 text-sm"
              />
            </div>
            <div class="w-1/6 p-2">
              <TextInput
                class="mb-4"
                type="text"
                :required="true"
                name="sub_total"
                v-model="formDetail.sub_total"
                :disabled="true"
                :label="trans('shopping.labels.sub_total')"
              />
            </div>
            <div class="w-1/6 p-2">
              <br />
              <a
                @click="addDetail()"
                class="px-4 py-2 border border-transparent text-sm rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 text-center transition text-white bg-theme-600 hover:bg-theme-800 focus:bg-theme-800 focus:ring-theme-800"
              >
                <i class="mr-1 fa fa-plus"></i>
                Agregar
              </a>
            </div>
          </div>
          <div>
            <div
              class="w-full shadow border-b border-gray-200 mb-8 sm:rounded-lg"
            >
              <table class="w-full divide-y divide-gray-200 table-auto">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="align-middle px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <div class="leading-loose inline-block">Producto</div>
                    </th>
                    <th
                      scope="col"
                      class="align-middle px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <div class="leading-loose inline-block">Marca</div>
                    </th>
                    <th
                      scope="col"
                      class="align-middle px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <div class="leading-loose inline-block">Tax</div>
                    </th>
                    <th
                      scope="col"
                      class="align-middle px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <div class="leading-loose inline-block">Cantidad</div>
                    </th>
                    <th
                      scope="col"
                      class="align-middle px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <div class="leading-loose inline-block">
                        Precio Unitario
                      </div>
                    </th>
                    <th
                      scope="col"
                      class="align-middle px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <div class="leading-loose inline-block">Sub total</div>
                    </th>
                    <th
                      scope="col"
                      class="align-middle px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Acciones
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="record in page.shoppingDetail">
                    <td class="px-6 py-4 whitespace-nowrap text-sm">
                      {{ record.product_name }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm">
                      {{ record.brand }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm">
                      {{ record.tax }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm">
                      {{ record.qty }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm">
                      {{ record.unit_price }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm">
                      {{ record.sub_total }}
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                    >
                      <a
                        @click="deleteRecord(record)"
                        class="uppercase cursor-pointer text-lg mr-3 text-danger-400"
                        title="Delete"
                      >
                        <i class="fa fa-trash"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Panel>
      </Form>

      <Modal
        :is-showing="page.isModalCreateProductShowing"
        @close="page.isModalCreateProductShowing = false"
      >
        <Panel>
          <div id="create-product">
            <div class="flex">
              <div class="w-1/2 p-2">
                <label :for="name" class="text-sm text-gray-500">
                  Categoría
                  <span class="text-red-600">*</span>
                </label>
                <select
                  class="mb-4 block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-theme-500 focus:border-theme-500 text-sm"
                  :required="true"
                  name="cate_id"
                  v-model="form2.cate_id"
                >
                  <option :value="null">Seleccione una categoría</option>
                  <option
                    v-for="item in page.categories"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="w-1/2 p-2">
                <TextInput
                  class="mb-4"
                  type="text"
                  :required="true"
                  name="name"
                  v-model="form2.name"
                  :label="trans('product.labels.name')"
                />
              </div>
            </div>
            <div class="flex">
              <div class="w-full p-2">
                <TextInput
                  class="mb-4"
                  type="textarea"
                  :required="true"
                  name="description"
                  v-model="form2.description"
                  :label="trans('product.labels.description')"
                />
              </div>
            </div>

            <!-- Botón para agregar variaciones -->
            <div class="flex justify-end p-2">
              <button
                @click="addVariation"
                class="px-4 py-2 border border-transparent text-sm rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 text-center transition text-white bg-theme-600 hover:bg-theme-800 focus:bg-theme-800 focus:ring-theme-800"
              >
                <i class="mr-1 fa fa-plus"></i>
                Agregar Variación
              </button>
            </div>

            <!-- Tabla de Variaciones -->
            <div class="p-2">
              <table class="w-full text-sm text-left text-gray-500">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                  <tr>
                    <th class="px-4 py-2">Variación</th>
                    <th class="px-4 py-2">SKU</th>
                    <th class="px-4 py-2">Precio</th>
                    <th class="px-4 py-2">Precio Embalaje</th>
                    <th class="px-4 py-2">Cantidad Mínima</th>
                    <th class="px-4 py-2">Cantidad Mínima (Embalaje)</th>
                    <th class="px-4 py-2">Imagen</th>
                    <th class="px-4 py-2">Imágenes Adicionales</th>
                    <th class="px-4 py-2">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(variation, index) in form2.variations"
                    :key="index"
                  >
                    <td class="px-4 py-2">
                      <TextInput
                        v-model="variation.description"
                        type="text"
                        placeholder="Variación"
                        required
                      />
                    </td>
                    <td class="px-4 py-2">
                      <TextInput
                        v-model="variation.sku"
                        type="text"
                        placeholder="SKU"
                        required
                      />
                    </td>
                    <td class="px-4 py-2">
                      <TextInput
                        v-model="variation.original_price"
                        type="text"
                        placeholder="Precio"
                        required
                      />
                    </td>
                    <td class="px-4 py-2">
                      <TextInput
                        v-model="variation.selling_price"
                        type="text"
                        placeholder="Precio Embalaje"
                        required
                      />
                    </td>
                    <td class="px-4 py-2">
                      <TextInput
                        v-model="variation.min_qty_mayor"
                        type="text"
                        placeholder="Cantidad Mínima"
                        required
                      />
                    </td>
                    <td class="px-4 py-2">
                      <TextInput
                        v-model="variation.min_qty_embalaje"
                        type="text"
                        placeholder="Cantidad Mínima (Embalaje)"
                        required
                      />
                    </td>
                    <!-- Columna para una sola imagen -->
                    <td class="px-4 py-2">
                      <input
                        type="file"
                        @change="handleImageUpload($event, index)"
                        accept="image/*"
                      />
                    </td>

                    <!-- Columna para múltiples imágenes -->
                    <td class="px-4 py-2">
                      <input
                        type="file"
                        multiple
                        @change="onFileChangeMultiple($event, index)"
                        accept="image/*"
                      />
                    </td>
                    <td class="px-4 py-2">
                      <button
                        @click="removeVariation(index)"
                        class="px-4 py-2 border border-transparent text-sm rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 text-center transition text-white bg-red-600 hover:bg-red-700 focus:bg-red-700 focus:ring-red-700"
                      >
                        <i class="mr-1 fa fa-trash"></i>
                        Eliminar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="flex justify-end mt-4 space-x-4">
              <button
                type="button"
                @click="clearForm"
                class="px-4 py-2 border border-transparent text-sm rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 text-center transition text-white bg-red-600 hover:bg-red-700 focus:bg-red-700 focus:ring-red-700"
              >
                <i class="mr-1 fa fa-ban"></i>
                Cancelar
              </button>

              <button
                type="button"
                @click="onSubmit_2"
                class="px-4 py-2 border border-transparent text-sm rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 text-center transition text-white bg-theme-600 hover:bg-theme-800 focus:bg-theme-800 focus:ring-theme-800"
              >
                <i class="mr-1 fa fa-save"></i>
                Guardar
              </button>
            </div>
          </div>
        </Panel>
      </Modal>
    </Panel>
  </Page>
</template>

<script>
import { defineComponent, reactive, onMounted } from 'vue'
import { trans } from '@/helpers/i18n'
import { useAuthStore } from '@/stores/auth'
import Button from '@/views/components/input/Button'
import TextInput from '@/views/components/input/TextInput'
import Dropdown from '@/views/components/input/Dropdown'
import Alert from '@/views/components/Alert'
import Modal from '@/views/components/Modal'
import Panel from '@/views/components/Panel'
import Page from '@/views/layouts/Page'
import FileInput from '@/views/components/input/FileInput'
import ShoppingService from '@/services/ShoppingService'
import CategoryService from '@/services/CategoryService'
import SupplierService from '@/services/SupplierService'
import ProductService from '@/services/ProductService'
import { clearObject, reduceProperties } from '@/helpers/data'
import { toUrl } from '@/helpers/routing'
import Form from '@/views/components/Form'
import { useAlertStore } from '@/stores'
import { getResponseError } from '@/helpers/api'
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelSelect } from 'vue-search-select'
import { ref } from 'vue'

import Swal from 'sweetalert2'
import axios from 'axios'

export default defineComponent({
  components: {
    Form,
    FileInput,
    Panel,
    Alert,
    Dropdown,
    TextInput,
    Button,
    Page,
    ModelSelect,
    Modal,
  },
  setup() {
    const { user } = useAuthStore()
    const form = reactive({
      description: '',
      supplier_id: null,
      total: '',
      shopping_date: '',
      document_number: '',
      // shopping_time: '',
      status: '1',
      document_number: '',
      detail: [],
    })

    const form2 = reactive({
      name: '',
      slug: '',
      small_description: '',
      description: '',
      cate_id: null,
      image: null,
      imageSrc: null,
      images: [],
      imagesSrc: [],
      status: '1',
      sku: '',
      qty: '',
      original_price: '',
      selling_price: '',
      tax: '',
      min_qty_mayor: '',
      min_qty_embalaje: '',
      variations: [], // Añadir variations al objeto reactivo
    })

    const formDetail = reactive({
      cate_id: null,
      category_name: null,
      product_id: null,
      product_name: null,
      brand: '',
      tax: '',
      qty: '',
      sub_total: '',
      unit_price: '',
    })

    const formVariation = reactive({
      variation: null,
      price: '',
    })

    const page = reactive({
      id: 'create_shopping',
      title: trans('global.pages.shopping_create'),
      filters: false,
      breadcrumbs: [
        {
          name: trans('global.pages.shoppings'),
          to: toUrl('/shopping/list'),
        },
        {
          name: trans('global.pages.shopping_create'),
          active: true,
        },
      ],
      actions: [
        {
          id: 'back',
          name: trans('global.buttons.back'),
          icon: 'fa fa-angle-left',
          to: toUrl('/shopping/list'),
          theme: 'outline',
        },
        {
          id: 'submit',
          name: trans('global.buttons.save'),
          icon: 'fa fa-save',
          type: 'submit',
        },
      ],
      products: [],
      suppliers: [],
      shoppingDetail: [],
      variations: [],
      isModalCreateProductShowing: false,
    })

    ///************************************ */

    // Array reactivo para las variaciones
    // const variations_2 = ref(form2.variations);

    // Función para agregar una nueva variación
    const addVariation = () => {
      try {
        console.log('Adding new variation', page.variations)
        console.log('Adding new variation!!!!!!', page.variations)

        page.variations.push({
          description: '',
          sku: '',
          original_price: '',
          selling_price: '',
          min_qty_mayor: '',
          min_qty_embalaje: '',
          imagesSrc: '',
        })
        form2.variations = page.variations;

        console.log('New variation added successfully')
      } catch (error) {
        console.error('Error adding new variation:', error)
        // Puedes manejar el error de alguna manera aquí, como mostrar un mensaje al usuario
      }
    }

    const removeVariation = (index) => {
      // console.log(`Removing variation at index ${index}`);
      form2.variations.splice(index, 1)
    }

    /************************************************** */

    const service = new ShoppingService()
    const categoryService = new CategoryService()
    const supplierService = new SupplierService()
    const productService = new ProductService()

    function onAction(data) {
      switch (data.action.id) {
        case 'submit':
          onSubmit()
          break
      }
    }

    function getSuppliers() {
      supplierService
        .index()
        .then((response) => {
          page.suppliers = response.data.data
        })
        .catch((error) => {
          alertStore.error(getResponseError(error))
          table.loading = false
        })
    }

    function getCategories() {
      categoryService
        .index()
        .then((response) => {
          page.categories = response.data.data
        })
        .catch((error) => {
          alertStore.error(getResponseError(error))
          table.loading = false
        })
    }

    function getProducts() {
      productService
        .getList()
        .then((response) => {
          //console.log("response", response)
          page.products = response.data.data
        })
        .catch((error) => {
          alert(`Error al obtener productos: ${error.message}`)
        })
    }

    function calculateSubTotal() {
      let subTotal = 0
      let unitPrice = formDetail.unit_price
      let qty = formDetail.qty
      subTotal = unitPrice * qty
      if (unitPrice > 0 && qty > 0) {
        this.formDetail.sub_total = subTotal
      }
    }

    function addDetail() {
      // Buscar el producto seleccionado en la lista de productos
      let selectedProduct = this.page.products.find(
        (product) => product.value == this.formDetail.product,
      )

      // Crear una nueva fila para el detalle de la compra
      let row = {
        product_id: this.formDetail.product,
        product_name: selectedProduct.text,
        brand: this.formDetail.brand,
        tax: this.formDetail.tax,
        qty: this.formDetail.qty,
        sub_total: this.formDetail.sub_total,
        unit_price: this.formDetail.unit_price,
      }

      // Agregar la nueva fila al detalle de la compra
      this.page.shoppingDetail.push(row)

      // Calcular el total
      let total = this.page.shoppingDetail.reduce(
        (acc, item) => acc + item.sub_total,
        0,
      )
      this.form.total = total
    }

    function deleteRecord(item) {
      let idx = this.page.shoppingDetail.indexOf(item)
      this.page.shoppingDetail.splice(idx, 1)
      let total = 0
      this.page.shoppingDetail.map((item) => {
        total = item.price * item.qty + total
      })
      this.form.total = total
    }

    function onSubmit() {
      form.detail = JSON.stringify(reduceProperties(page.shoppingDetail))

      service
        .handleCreate('create-shopping', reduceProperties(form, 'id'))
        .then(() => {
          clearObject(form)
          clearObject(formDetail) // Limpia los detalles individuales
          page.shoppingDetail = [] // Limpia el detalle de la compra
        })

      return false
    }

    async function handleImageUpload(event, index) {
      const file = event.target.files[0] // Captura la primera imagen seleccionada
      if (file) {
        const imageUrl = URL.createObjectURL(file)
        this.form2.variations[index].imageSrc = imageUrl
        // Convertir a archivo binario
        this.form2.variations[index].imageFile = file
      }
    }

    async function onFileChangeMultiple(event, index) {
      const files = event.target.files // Captura todas las imágenes seleccionadas
      if (files.length) {
        const imageUrls = Array.from(files).map((file) =>
          URL.createObjectURL(file),
        )
        this.form2.variations[index].imagesSrc = imageUrls
        // Convertir a archivos binarios
        this.form2.variations[index].imageFiles = Array.from(files)
      }
    }

    function onSubmit_2() {
      // Validar campos de la cabecera
      if (
        !form2.name ||
        !form2.cate_id ||
        !form2.description ||
        !form2.status
      ) {
        Swal.fire({
          icon: 'info',
          title: 'Alerta',
          text: 'Todos los campos de la cabecera deben estar completos.',
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#3085d6',
        })
        return
      }

      // Validar que haya al menos una variación completa
      const hasCompleteVariation = form2.variations.some(
        (variation) =>
          variation.description &&
          variation.sku &&
          variation.original_price &&
          variation.selling_price &&
          variation.min_qty_mayor &&
          variation.min_qty_embalaje &&
          (variation.imageFile ||
            (variation.imageFiles && variation.imageFiles.length)),
      )

      if (!hasCompleteVariation) {
        Swal.fire({
          icon: 'info',
          title: 'Alerta',
          text: 'Debe completar todos los campos de la variación.',
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#3085d6',
        })
        return
      }

      const formData = new FormData()

      // Agregar los datos de la cabecera
      formData.append('nombre', form2.name)
      formData.append('categoria', form2.cate_id)
      formData.append('descripcion', form2.description)
      formData.append('estado', form2.status)

      // Agregar los datos de las variaciones
      form2.variations.forEach((variation, index) => {
        if (
          variation.description &&
          variation.sku &&
          variation.original_price &&
          variation.selling_price &&
          variation.min_qty_mayor &&
          variation.min_qty_embalaje
        ) {
          formData.append(
            `variations[${index}][variacion]`,
            variation.description,
          )
          formData.append(`variations[${index}][sku]`, variation.sku)
          formData.append(
            `variations[${index}][precio_original]`,
            variation.original_price,
          )
          formData.append(
            `variations[${index}][precio_embalaje]`,
            variation.selling_price,
          )
          formData.append(
            `variations[${index}][cantidad_minima]`,
            variation.min_qty_mayor,
          )
          formData.append(
            `variations[${index}][cantidad_minima_embalaje]`,
            variation.min_qty_embalaje,
          )

          if (variation.imageFile) {
            formData.append(`variations[${index}][imagen]`, variation.imageFile)
          }

          if (variation.imageFiles && variation.imageFiles.length) {
            variation.imageFiles.forEach((file, fileIndex) => {
              formData.append(
                `variations[${index}][imagenes_adicionales][${fileIndex}]`,
                file,
              )
            })
          }
        }
      })

      axios
        .post('/api/shopping/registrar_productos_variacion', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          // Extraer el mensaje del objeto de respuesta
          const message =
            response.data.message || 'Productos registrados correctamente'
          Swal.fire({
            icon: 'success',
            title: 'Productos Registrados',
            text: message,
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#3085d6',
          })

          clearForm()
          getProducts()
        })
        .catch((error) => {
          console.error('Error al enviar los datos:', error)
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Ocurrió un error al registrar los productos.',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#3085d6',
          })
        })
    }

    // Función para limpiar el formulario
    function clearForm() {
      // Limpiar el formulario
      form2.name = ''
      form2.cate_id = null
      form2.description = ''
      //form2.status = '';

      // Limpiar el array de variaciones
      form2.variations.splice(0, form2.variations.length)

      // Cerrar el modal
      page.isModalCreateProductShowing = false
    }

    const statusList = [
      { id: 0, title: 'Active' },
      { id: 1, title: 'Inactive' },
    ]

    onMounted(() => {
      getCategories()
      getProducts()
      getSuppliers()
      // getVariations()
    })

    return {
      trans,
      user,
      form,
      page,
      onSubmit,
      onSubmit_2,
      clearForm,
      onAction,
      statusList,
      formDetail,
      calculateSubTotal,
      addDetail,
      deleteRecord,
      formVariation,

      handleImageUpload, // Asegúrate de devolver la función aquí
      onFileChangeMultiple,
      form2,
      addVariation,
      removeVariation,
    }
  },
})
</script>

<style scoped></style>

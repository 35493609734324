<template>
  <Page
    :title="page.title"
    :breadcrumbs="page.breadcrumbs"
    :actions="page.actions"
    @action="onAction"
    class="w-full m-5"
  >
    <Panel>
      <Form id="create-user" @submit.prevent="onSubmit">
        <TextInput
          class="mb-4"
          type="text"
          :required="true"
          name="first_name"
          v-model="form.first_name"
          :label="trans('users.labels.first_name')"
        />
        <TextInput
          class="mb-4"
          type="text"
          :required="true"
          name="last_name"
          v-model="form.last_name"
          :label="trans('users.labels.last_name')"
        />
        <TextInput
          class="mb-4"
          type="email"
          :required="true"
          name="email"
          v-model="form.email"
          :label="trans('users.labels.email')"
        />
        <label :for="role" class="text-sm text-gray-500">
          Rol
          <span class="text-red-600">*</span>
        </label>
        <select
          class="mb-4 block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-theme-500 focus:border-theme-500 text-sm"
          :required="true"
          name="role"
          @change="addRole()"
          v-model="form.role"
          :label="trans('users.labels.role')"
        >
          <option :value="null">Seleccione un rol</option>
          <option v-for="role in page.roles" :value="role.id">{{ role.title }}</option>
        </select>
        <FileInput
          class="mb-4"
          name="avatar"
          v-model="form.avatar"
          accept="image/*"
          :label="trans('users.labels.avatar')"
          @click="form.avatar = ''"
        ></FileInput>
        <TextInput
          class="mb-4"
          type="password"
          :required="true"
          name="password"
          v-model="form.password"
          :label="trans('users.labels.password')"
        />
      </Form>
    </Panel>
  </Page>
</template>

<script>
import { defineComponent, reactive,onMounted } from 'vue'
import { trans } from '@/helpers/i18n'
import { useAuthStore } from '@/stores/auth'
import Button from '@/views/components/input/Button'
import TextInput from '@/views/components/input/TextInput'
import Dropdown from '@/views/components/input/Dropdown'
import Alert from '@/views/components/Alert'
import Panel from '@/views/components/Panel'
import Page from '@/views/layouts/Page'
import FileInput from '@/views/components/input/FileInput'
import UserService from '@/services/UserService'
import { clearObject, reduceProperties } from '@/helpers/data'
import { toUrl } from '@/helpers/routing'
import Form from '@/views/components/Form'

export default defineComponent({
  components: {
    Form,
    FileInput,
    Panel,
    Alert,
    Dropdown,
    TextInput,
    Button,
    Page,
  },
  setup() {
    const { user } = useAuthStore()
    const form = reactive({
      first_name: '',
      last_name: '',
      middle_name: '',
      email: '',
      role: null,
      roles:[],
      avatar: '',
      password: '',
    })

    const page = reactive({
      id: 'create_users',
      title: trans('global.pages.users_create'),
      filters: false,
      roles: [],
      breadcrumbs: [
        {
          name: trans('global.pages.users'),
          to: toUrl('/users/list'),
        },
        {
          name: trans('global.pages.users_create'),
          active: true,
        },
      ],
      actions: [
        {
          id: 'back',
          name: trans('global.buttons.back'),
          icon: 'fa fa-angle-left',
          to: toUrl('/users/list'),
          theme: 'outline',
        },
        {
          id: 'submit',
          name: trans('global.buttons.save'),
          icon: 'fa fa-save',
          type: 'submit',
        },
      ],
    })

    const service = new UserService()

    function onAction(data) {
      switch (data.action.id) {
        case 'submit':
          onSubmit()
          break
      }
    }
    
    function addRole(){
      form.roles = [];
      form.roles.push(form.role);
      console.log("form.roles",form.roles)
    }

    function getRoles() {
      service.getRoles().then((r) => {
        page.roles = r.data.data
      })
    }

    function onSubmit() {
      service
        .handleCreate('create-user', reduceProperties(form, 'id'))
        .then(() => {
          clearObject(form)
        })
      return false
    }

    onMounted(() => {
      getRoles()
    })

    return {
      trans,
      user,
      form,
      page,
      onSubmit,
      onAction,
      addRole
    }
  },
})
</script>

<style scoped></style>

<template>
  <Page
    :title="page.title"
    :breadcrumbs="page.breadcrumbs"
    :actions="page.actions"
    @action="onAction"
    class="w-full m-5"
  >
    <Panel>
      <Form id="create-order" @submit.prevent="onSubmit">
        <div class="flex">
          <div class="w-1/4 p-2">
            <TextInput
              class="mb-4"
              type="text"
              :required="true"
              name="document_number"
              v-model="form.document_number"
              :label="trans('order.labels.document_number')"
            />
          </div>
          <div class="w-1/3 p-2">
            <TextInput
              class="mb-4"
              type="text"
              :required="true"
              name="customer_id"
              v-model="form.customer_id"
              :label="trans('order.labels.customer_id')"
            />
          </div>
          <div class="w-1/4 p-2">
            <TextInput
              class="mb-4"
              type="date"
              :required="true"
              name="order_date"
              v-model="form.order_date"
              :label="trans('order.labels.order_date')"
            />
          </div>
          <div class="w-1/4 p-2">
            <TextInput
              class="mb-4"
              type="time"
              :required="true"
              name="order_time"
              v-model="form.order_time"
              :label="trans('order.labels.order_time')"
            />
          </div>
          <div class="w-1/4 p-2">
            <TextInput
              class="mb-4"
              type="text"
              :required="true"
              name="total"
              :disabled="true"
              v-model="form.total_price"
              :label="trans('order.labels.total')"
            />
          </div>
          <div class="w-1/4 p-2">
            <label :for="name" class="text-sm text-white">
              Estado
              <span class="text-red-600">*</span>
            </label>
            <select
              class="mb-4 block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-theme-500 focus:border-theme-500 text-sm"
              :required="true"
              name="status"
              v-model="form.status"
              :label="trans('order.labels.status')"
            >
              <option value="1">Activo</option>
              <option value="0">Desactivado</option>
            </select>
          </div>
        </div>
        <div class="flex">
          <div class="w-1/4 p-2">
            <TextInput
              class="mb-4"
              type="text"
              :required="true"
              name="lname"
              v-model="form.lname"
              :label="trans('order.labels.lname')"
            />
          </div>
          <div class="w-1/3 p-2">
            <TextInput
              class="mb-4"
              type="text"
              :required="true"
              name="email"
              v-model="form.email"
              :label="trans('order.labels.email')"
            />
          </div>
          <div class="w-1/4 p-2">
            <TextInput
              class="mb-4"
              type="text"
              :required="true"
              name="phoneno"
              v-model="form.phoneno"
              :label="trans('order.labels.phoneno')"
            />
          </div>
          <div class="w-1/4 p-2">
            <TextInput
              class="mb-4"
              type="text"
              :required="true"
              name="address1"
              v-model="form.address1"
              :label="trans('order.labels.address1')"
            />
          </div>
          <div class="w-1/4 p-2">
            <TextInput
              class="mb-4"
              type="text"
              :required="false"
              name="address2"
              :disabled="true"
              v-model="form.address2"
              :label="trans('order.labels.address2')"
            />
          </div>
        </div>
        <div class="flex">
          <div class="w-full p-2">
            <TextInput
              class="mb-4"
              type="textarea"
              :required="true"
              name="description"
              v-model="form.message"
              :label="trans('order.labels.message')"
            />
          </div>
        </div>

        <Panel>
          <h3>Detalle</h3>
          <br />
          <div class="flex">
            <div class="w-1/3 p-2">
              <label :for="name" class="text-sm text-white">
                Producto
                <span class="text-red-600">*</span>
              </label>
              <model-select
                :options="page.products"
                v-model="formDetail.product"
                placeholder="Seleccione un producto"
              ></model-select>
            </div>

            <div class="w-1/6 p-2">
              <label class="text-sm text-white">
                {{ trans('order.labels.qty') }}
                <span class="text-red-600">*</span>
              </label>

              <input
                type="text"
                :required="true"
                name="qty"
                @input="calculateSubTotal()"
                v-model="formDetail.qty"
                class="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-theme-500 focus:border-theme-500 text-sm"
              />
            </div>
            <div class="w-1/6 p-2">
              <label class="text-sm text-white">
                {{ trans('order.labels.price') }}
                <span class="text-red-600">*</span>
              </label>
              <input
                type="text"
                :required="true"
                name="price"
                @input="calculateSubTotal()"
                v-model="formDetail.price"
                class="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-theme-500 focus:border-theme-500 text-sm"
              />
            </div>
            <div class="w-1/6 p-2">
              <TextInput
                class="mb-4"
                type="text"
                :required="true"
                name="sub_total"
                v-model="formDetail.sub_total"
                :disabled="true"
                :label="trans('order.labels.sub_total')"
              />
            </div>
            <div class="w-1/6 p-2">
              <br />
              <a
                @click="addDetail()"
                class="px-4 py-2 border border-transparent text-sm rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 text-center transition text-white bg-theme-600 hover:bg-theme-800 focus:bg-theme-800 focus:ring-theme-800"
              >
                <i class="mr-1 fa fa-plus"></i>
                Agregar
              </a>
            </div>
          </div>
          <div>
            <div
              class="w-full shadow border-b border-gray-200 mb-8 sm:rounded-lg"
            >
              <table class="w-full divide-y divide-gray-200 table-auto">
                <thead class="bg-black">
                  <tr>
                    <th
                      scope="col"
                      class="align-middle px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
                    >
                      <div class="leading-loose inline-block">Producto</div>
                    </th>

                    <th
                      scope="col"
                      class="align-middle px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
                    >
                      <div class="leading-loose inline-block">Cantidad</div>
                    </th>
                    <th
                      scope="col"
                      class="align-middle px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
                    >
                      <div class="leading-loose inline-block">
                        Precio Unitario
                      </div>
                    </th>
                    <th
                      scope="col"
                      class="align-middle px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
                    >
                      <div class="leading-loose inline-block">Sub total</div>
                    </th>
                    <th
                      scope="col"
                      class="align-middle px-6 py-3 text-right text-xs font-medium text-white uppercase tracking-wider"
                    >
                      Acciones
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="record in form.detail">
                    <td class="px-6 py-4 whitespace-nowrap text-sm">
                      {{ record.products.name }}
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap text-sm">
                      {{ record.qty }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm">
                      {{ record.price }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm">
                      {{ record.price * record.qty }}
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                    >
                      <a
                        @click="deleteRecord(record)"
                        class="uppercase cursor-pointer text-lg mr-3 text-danger-400"
                        title="Delete"
                      >
                        <i class="fa fa-trash"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Panel>
      </Form>
    </Panel>
  </Page>
</template>

<script>
import { defineComponent, reactive, onMounted, onBeforeMount } from 'vue'
import { trans } from '@/helpers/i18n'
import { useAuthStore } from '@/stores/auth'
import { useRoute } from 'vue-router'
import Button from '@/views/components/input/Button'
import TextInput from '@/views/components/input/TextInput'
import Dropdown from '@/views/components/input/Dropdown'
import Alert from '@/views/components/Alert'
import Modal from '@/views/components/Modal'
import Panel from '@/views/components/Panel'
import Page from '@/views/layouts/Page'
import FileInput from '@/views/components/input/FileInput'
import OrderService from '@/services/OrderService'
import CategoryService from '@/services/CategoryService'
import SupplierService from '@/services/SupplierService'
import ProductService from '@/services/ProductService'
import { clearObject, fillObject, reduceProperties } from '@/helpers/data'
import { toUrl } from '@/helpers/routing'
import Form from '@/views/components/Form'
import { useAlertStore } from '@/stores'
import { getResponseError } from '@/helpers/api'
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelSelect } from 'vue-search-select'
export default defineComponent({
  components: {
    Form,
    FileInput,
    Panel,
    Alert,
    Dropdown,
    TextInput,
    Button,
    Page,
    ModelSelect,
    Modal,
  },
  setup() {
    const { user } = useAuthStore()
    const route = useRoute()
    const form = reactive({
      order_id:null,
      address1: '',
      description: '',
      customer_id: null,
      total_price: '',
      lname: '',
      email: '',
      phoneno: '',
      message: '',
      order_date: '',
      document_number: '',
      order_time: '',
      status: '1',
      document_number: '',
      detail: [],
    })

    const formDetail = reactive({
      cate_id: null,
      product_id: null,
      product_name: null,
      brand: '',
      tax: '',
      qty: '',
      sub_total: '',
      price: '',
    })

    const page = reactive({
      id: 'create_order',
      title: trans('global.pages.order_create'),
      filters: false,
      breadcrumbs: [
        {
          name: trans('global.pages.orders'),
          to: toUrl('/order/list'),
        },
        {
          name: trans('global.pages.order_create'),
          active: true,
        },
      ],
      actions: [
        {
          id: 'back',
          name: trans('global.buttons.back'),
          icon: 'fa fa-angle-left',
          to: toUrl('/order/list'),
          theme: 'outline',
        },
        {
          id: 'submit',
          name: trans('global.buttons.save'),
          icon: 'fa fa-save',
          type: 'submit',
        },
      ],
      products: [],
      orderDetail: [],
    })

    const service = new OrderService()
    const categoryService = new CategoryService()
    const supplierService = new SupplierService()
    const productService = new ProductService()

    function onAction(data) {
      switch (data.action.id) {
        case 'submit':
          onSubmit()
          break
      }
    }

    function getProducts() {
      productService
        .getList()
        .then((response) => {
          page.products = response.data.data
        })
        .catch((error) => {
          alertStore.error(getResponseError(error))
          table.loading = false
        })
    }

    function calculateSubTotal() {
      let subTotal = 0
      let unitPrice = formDetail.price
      let qty = formDetail.qty
      subTotal = unitPrice * qty
      if (unitPrice > 0 && qty > 0) {
        this.formDetail.sub_total = subTotal
      }
    }

    function deleteRecord(item) {
      let idx = form.detail.indexOf(item)
      form.detail.splice(idx, 1)
      let total = 0
      form.detail.map((item) => {
        total = (item.price*item.qty) + total
      })
      this.form.total_price = total
    }

    function addDetail() {
      let product = this.page.products.find(
        (item) => item.value == formDetail.product,
      )

      let row = {
        products: { id: product.value, name: product.text },
        prod_id: product.value,
        order_id:route.params.id,
        qty: formDetail.qty,
        sub_total: formDetail.sub_total,
        price: formDetail.price,
      }
      console.log("form.detail.",form.detail)
      form.detail.push(row)
      let total = 0
      form.detail.map((item) => {
        total = (item.price*item.qty) + total
      })
      this.form.total_price = total
    }

    function onSubmit() {
      form.detail = JSON.stringify(reduceProperties(form.detail))
      service
        .handleUpdate('edit-order', route.params.id, reduceProperties(form,'id'))
        .then(() => {
          clearObject(form)
        })
      return false
    }

    const statusList = [
      { id: 0, title: 'Active' },
      { id: 1, title: 'Inactive' },
    ]

    onMounted(() => {
      getProducts()
    })

    onBeforeMount(() => {
      service.edit(route.params.id).then((response) => {
        fillObject(form, response.data.model);
        form.order_id=route.params.id;
        page.loading = false
      })
    })

    return {
      trans,
      user,
      form,
      page,
      onSubmit,
      getProducts,
      onAction,
      statusList,
      formDetail,
      calculateSubTotal,
      addDetail,
      deleteRecord,
    }
  },
})
</script>

<style scoped></style>

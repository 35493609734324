<template>
  <Page
    :title="page.title"
    :breadcrumbs="page.breadcrumbs"
    :actions="page.actions"
    @action="onAction"
    class="w-full m-5"
  >
  <Panel>
      <div class="flex">
        <div class="w-1/2 text-center">
          <label for=""><b>Estado de Pedido</b></label>
          <br />
          <br />
          <span
            class="px-2 mx-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-green-800"
            :class="page.order.status == 0 ? 'bg-green-100' : ''"
          >
            RECIBIDO
          </span>
          <span
            class="px-2 mx-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-green-800"
            :class="page.order.status == 1 ? 'bg-green-100' : ''"
          >
            EN PREPARACIÓN
          </span>
          <span
            class="px-2 mx-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-green-800"
            :class="page.order.status == 2 ? 'bg-green-100' : ''"
          >
            ENVIADO
          </span>
        </div>
        <div class="w-1/2 text-center">
          <label for=""><b>Estado de Pago</b></label>
          <br />
          <br />
          <span
            class="px-2 mx-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-green-800"
            :class="page.order.payment_status == 0 ? 'bg-green-100' : ''"
          >
            RECHAZADO
          </span>
          <span
            class="px-2 mx-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-green-800"
            :class="page.order.payment_status == 1 ? 'bg-green-100' : ''"
          >
            PENDIENTE
          </span>
          <span
            class="px-2 mx-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-green-800"
            :class="page.order.payment_status == 2 ? 'bg-green-100' : ''"
          >
            PAGADO
          </span>
        </div>
      </div>
    </Panel>
    <div class="p-2 panel-gray">
      <div class="flex">
        <div class="w-1/4 p-2">
          <img width="200" src="/assets/images/logo.png" />
          <p class="mt-5 text-primary">
            Grajales 2955, Santiago - Chile/Movil: +56 945220011
          </p>
        </div>
        <div class="w-1/4 p-2"></div>
        <div class="w-1/4 p-2"></div>

        <div class="w-1/4 p-2">
          <Button
            @click="exportToPDF"
            class="btn btn-primary m-1"
            :icon="'fa fa-print'"
            :label="'Imprimir'"
          ></Button>

          <Button
            v-if="!page.order.has_sale"
            class="btn btn-primary m-1"
            @click="emitirOrdenVenta()"
            :label="'Emitir Orden de Venta'"
          ></Button>
          <br class="mt-2 mb-2" />
          <div class="flex">
            <div class="w-1/3">
              <label class="text-primary" for=""><b>NRO PEDIDO</b></label>
            </div>
            <div class="w-1/4">
              <label class="text-primary" for="">
                {{ page.order.document_number }}
              </label>
            </div>
          </div>
          <div class="flex">
            <div class="w-1/3">
              <label class="text-primary" for=""><b>FECHA</b></label>
            </div>
            <div class="w-1/4">
              <label class="text-primary" for="">
                {{ page.order.order_date }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="flex mt-4">
        <div class="w-1/6">
          <label for=""><b>Cliente</b></label>
        </div>
        <div class="w-1/4">
          <label for="">{{ page.order.lname }}</label>
        </div>
        <div class="w-1/6">
          <label for=""><b>Teléfono</b></label>
        </div>
        <div class="w-1/4">
          <label for="">{{ page.order.phoneno }}</label>
        </div>
        <div class="w-1/6">
          <label for=""><b>RUT</b></label>
        </div>
        <div class="w-1/4">
          <label for="">{{ page.order.customer_id }}</label>
        </div>
        <div class="w-1/6">
          <label for=""><b>Dirección</b></label>
        </div>
        <div class="w-1/4">
          <label for="">{{ page.order.address1 }}</label>
        </div>
      </div>
    </div>
    <Panel>
      <table class="w-full divide-y divide-gray-200 table-auto">
        <thead class="bg-black">
          <tr>
            <th
              scope="col"
              class="align-middle px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
            >
              <div class="leading-loose inline-block">Producto</div>
            </th>
            <th
              scope="col"
              class="align-middle px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
            >
              <div class="leading-loose inline-block">Cantidad</div>
            </th>
            <th
              scope="col"
              class="align-middle px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
            >
              <div class="leading-loose inline-block">Unidad</div>
            </th>
            <th
              scope="col"
              class="align-middle px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
            >
              <div class="leading-loose inline-block">Total</div>
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="record in page.order.detail">
            <td class="px-6 py-4 whitespace-nowrap text-sm">
              <img width="100" :src="record.products.image">
              <b>{{ record.products.name }}</b>
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm">
              {{ record.qty }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm">
              {{ record.price }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
              {{ record.price * record.qty }}
            </td>
          </tr>
        </tbody>
      </table>
    </Panel>
    <div id="report">
      <div class="p-2">
        <div class="">
          <figure style="display: inline-block;">
            <img width="200" src="/assets/images/logo.png" />
          </figure>
          <div class="float-right">
            <p class="text-primary label-250" for=""><b>NRO PEDIDO</b></p>

            <label class="text-primary" for="">
              {{ page.order.document_number }}
            </label>
            <br />
            <p class="text-primary label-250" for=""><b>FECHA</b></p>

            <label class="text-primary" for="">
              {{ page.order.order_date }}
            </label>
            <br />
            <p class="text-primary label-250" for=""><b>ESTADO DE PAGO</b></p>

            <label class="text-primary" for="">
              {{ page.order.payment_status_label }}
            </label>
            <br />
            <p class="text-primary label-250" for=""><b>ESTADO DE PEDIDO</b></p>

            <label class="text-primary" for="">
              {{ page.order.status_label }}
            </label>
            <br />
          </div>
          <br />
          <label class="mt-5 text-primary">
            Grajales 2955, Santiago - Chile/Movil: +56 945220011
          </label>
        </div>
        <br />
        <hr />
        <br />

        <p class="label-200" for=""><b>Cliente</b></p>

        <label for="">{{ page.order.lname }}</label>
        <br />

        <p class="label-200" for=""><b>Teléfono</b></p>

        <label for="">{{ page.order.phoneno }}</label>
        <br />

        <p class="label-200" for=""><b>RUT</b></p>

        <label for="">{{ page.order.customer_id }}</label>
        <br />

        <p class="label-200" for=""><b>Dirección</b></p>

        <label for="">{{ page.order.address1 }}</label>
        <br />
      </div>
      <br><br>
      <div>
        <table class="w-full divide-y divide-gray-200 table-auto">
          <thead class="bg-black">
            <tr>
              <th
                scope="col"
                class="align-middle px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
              >
                <div class="leading-loose inline-block">Producto</div>
              </th>
              <th
                scope="col"
                class="align-middle px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
              >
                <div class="leading-loose inline-block">Cantidad</div>
              </th>
              <th
                scope="col"
                class="align-middle px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
              >
                <div class="leading-loose inline-block">Unidad</div>
              </th>
              <th
                scope="col"
                class="align-middle px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
              >
                <div class="leading-loose inline-block">Total</div>
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="record in page.order.detail">
              <td class="px-6 py-4 whitespace-nowrap text-sm">
                {{ record.products.name }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm">
                {{ record.qty }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm">
                {{ record.price }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
                {{ record.price * record.qty }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    
  </Page>
</template>

<script>
import { defineComponent, reactive, onMounted } from 'vue'
import { trans } from '@/helpers/i18n'
import { useAuthStore } from '@/stores/auth'
import Button from '@/views/components/input/Button'
import TextInput from '@/views/components/input/TextInput'
import Dropdown from '@/views/components/input/Dropdown'
import Alert from '@/views/components/Alert'
import Modal from '@/views/components/Modal'
import Panel from '@/views/components/Panel'
import Page from '@/views/layouts/Page'
import FileInput from '@/views/components/input/FileInput'
import OrderService from '@/services/OrderService'
import CategoryService from '@/services/CategoryService'
import SupplierService from '@/services/SupplierService'
import ProductService from '@/services/ProductService'
import { clearObject, reduceProperties } from '@/helpers/data'
import { toUrl } from '@/helpers/routing'
import Form from '@/views/components/Form'
import { useAlertStore } from '@/stores'
import { getResponseError } from '@/helpers/api'
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelSelect } from 'vue-search-select'
import { useRoute } from 'vue-router'
import html2pdf from 'html2pdf.js'

export default defineComponent({
  components: {
    Form,
    FileInput,
    Panel,
    Alert,
    Dropdown,
    TextInput,
    Button,
    Page,
    ModelSelect,
    Modal,
  },
  setup() {
    const { user } = useAuthStore()
    const route = useRoute()
    const form = reactive({
      description: '',
      supplier_id: null,
      total: '',
      order_date: '',
      document_number: '',
      order_time: '',
      status: '1',
      document_number: '',
      detail: [],
    })

    const formDetail = reactive({
      cate_id: null,
      product_id: null,
      product_name: null,
      brand: '',
      tax: '',
      qty: '',
      sub_total: '',
      price: '',
    })

    const page = reactive({
      id: 'create_order',
      title: trans('Detalles del pedido'),
      filters: false,
      breadcrumbs: [
        {
          name: trans('global.pages.orders'),
          to: toUrl('/order/list'),
        },
        {
          name: trans('Detalles del pedido'),
          active: true,
        },
      ],
      actions: [
        {
          id: 'back',
          name: trans('global.buttons.back'),
          icon: 'fa fa-angle-left',
          to: toUrl('/order/list'),
          theme: 'outline',
        },
      ],
      products: [],
      order: { has_sale: true },
      orderDetail: [],
      bancos:[]
    })

    const service = new OrderService()
    const categoryService = new CategoryService()
    const supplierService = new SupplierService()
    const productService = new ProductService()

    function onAction(data) {
      switch (data.action.id) {
        case 'submit':
          onSubmit()
          break
      }
    }
    function emitirOrdenVenta() {
      service
        .emitirOrdenVenta(route.params.id)
        .then((response) => {
          getOrder()
        })
        .catch((error) => {})
    }
    function getBancos(){
      service.getBancos().then((r) =>{
        page.bancos = r.data;
      })
    }
    async function exportToPDF() {
      document.getElementById('report').style.display='block';
      await html2pdf(document.getElementById('report'));
      document.getElementById('report').style.display='none';
    }

    function getOrder() {
      service
        .edit(route.params.id)
        .then((response) => {
          page.order = response.data.model
          console.log('pagte', page.order)
          console.log('response', response)
        })
        .catch((error) => {})
    }

    function getProducts() {
      productService
        .getList()
        .then((response) => {
          page.products = response.data.data
        })
        .catch((error) => {
          alertStore.error(getResponseError(error))
          table.loading = false
        })
    }

    function calculateSubTotal() {
      let subTotal = 0
      let unitPrice = formDetail.price
      let qty = formDetail.qty
      subTotal = unitPrice * qty
      if (unitPrice > 0 && qty > 0) {
        this.formDetail.sub_total = subTotal
      }
    }

    function addDetail() {
      let product = this.page.products.find(
        (item) => item.value == this.formDetail.product,
      )
      let row = {
        product_id: product.value,
        product_name: product.text,
        qty: this.formDetail.qty,
        sub_total: this.formDetail.sub_total,
        price: this.formDetail.price,
      }
      this.page.orderDetail.push(row)
      let total = 0
      this.page.orderDetail.map((item) => {
        total = item.sub_total + total
      })
      this.form.total = total
    }

    function onSubmit() {
      console.log('this.page.orderDetail', page.orderDetail)
      form.detail = JSON.stringify(reduceProperties(page.orderDetail))
      service
        .handleCreate('create-order', reduceProperties(form, 'id'))
        .then(() => {
          clearObject(form)
        })
      return false
    }

    const statusList = [
      { id: 0, title: 'Active' },
      { id: 1, title: 'Inactive' },
    ]

    onMounted(() => {
      getOrder()
    })

    return {
      trans,
      user,
      form,
      page,
      onSubmit,
      getProducts,
      getOrder,
      onAction,
      statusList,
      formDetail,
      calculateSubTotal,
      addDetail,
      emitirOrdenVenta,
      exportToPDF,
    }
  },
})
</script>

<style scoped>
.rounded-full {
  padding: 8px;
}
.panel-gray {
  background-color: #f1f1f1;
}
.label-250 {
  display: inline-block;
  width: 160px;
}
.label-200 {
  display: inline-block;
  width: 120px;
}
.float-right {
  float: right;
}
#report{
  padding: 50px;
  display: none;
}
</style>

<template>
  <Page
    :title="page.title"
    :breadcrumbs="page.breadcrumbs"
    :actions="page.actions"
    @action="onAction"
    class="w-full m-5"
  >
    <Panel>
      <div class="flex">
        <div class="w-1/2 text-center">
          <label for=""><b>Estado de Pedido</b></label>
          <br />
          <br />
          <span
            class="px-2 mx-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-green-800"
            :class="page.order.status == 0 ? 'bg-green-100' : ''"
          >
            RECIBIDO
          </span>
          <span
            class="px-2 mx-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-green-800"
            :class="page.order.status == 1 ? 'bg-green-100' : ''"
          >
            EN PREPARACIÓN
          </span>
          <span
            class="px-2 mx-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-green-800"
            :class="page.order.status == 2 ? 'bg-green-100' : ''"
          >
            ENVIADO
          </span>
        </div>
        <div class="w-1/2 text-center">
          <label for=""><b>Estado de Pago</b></label>
          <br />
          <br />
          <span
            class="px-2 mx-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-green-800"
            :class="page.order.payment_status == 0 ? 'bg-green-100' : ''"
          >
            RECHAZADO
          </span>
          <span
            class="px-2 mx-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-green-800"
            :class="page.order.payment_status == 1 ? 'bg-green-100' : ''"
          >
            PENDIENTE
          </span>
          <span
            class="px-2 mx-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-green-800"
            :class="page.order.payment_status == 2 ? 'bg-green-100' : ''"
          >
            PAGADO
          </span>
        </div>
      </div>
    </Panel>
    <!-- <Panel>
      <div class="mb-2">
        <h4 class="text-primary">DATOS DE PAGO</h4>
      </div>
      <Form id="create-variation" @submit.prevent="onSubmitPayment">
        <div class="flex">
          <div class="w-1/4 mx-2">
            <a
              href="#"
              @click="newBanco()"
              style="float: right;"
              class="text-primary"
            >
              <i class="mr-1 fa fa-plus"></i>
            </a>
            <label class="text-sm text-gray-500">Banco</label>
            <select
              class="mb-4 block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-theme-500 focus:border-theme-500 text-sm"
              name=""
              v-model="formPayment.bank_id"
              id=""
            >
              <option :value="item.id" v-for="item in page.bancos">
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="w-1/4 mx-2">
            <TextInput
              class="mb-4"
              type="text"
              v-model="formPayment.sender"
              :required="true"
              :label="'Remitente'"
            />
          </div>
          <div class="w-1/4 mx-2">
            <TextInput
              class="mb-4"
              type="text"
              v-model="formPayment.amount"
              :required="true"
              :label="'Monto'"
            />
          </div>
          <div class="w-1/4 mx-2">
            <TextInput
              class="mb-4"
              type="text"
              v-model="formPayment.operation_number"
              :required="true"
              :label="'Nro Operación'"
            />
          </div>
          <div v-if="page.order.payments?.length==0" class="text-right mt-5">
            <Button
              class="btn btn-primary"
              :icon="'fa fa-save'"
              :label="'Guardar'"
            ></Button>
          </div>
        </div>
      </Form>
    </Panel> -->
    <div class="p-2 panel-gray">
      <div class="flex">
        <div class="w-1/4 p-2">
          <img width="200" src="/assets/images/logo.png" />
          <p class="mt-5 text-primary">
            Grajales 2955, Santiago - Chile/Movil: +56 945220011
          </p>
        </div>
        <div class="w-1/4 p-2"></div>
        <div class="w-1/4 p-2"></div>
        <div class="w-1/4 p-2">
          <Button
            class="btn btn-primary ml-60"
            :icon="'fa fa-print'"
            :label="'Imprimir'"
          ></Button>

          <br class="mt-2 mb-2" />
          <div class="flex">
            <div class="w-1/2">
              <label class="text-primary" for="">
                <b>NRO ORDEN DE VENTA</b>
              </label>
            </div>
            <div class="w-1/4">
              <label class="text-primary" for="">
                {{ page.order.id }}
              </label>
            </div>
          </div>
          <div class="flex">
            <div class="w-1/2">
              <label class="text-primary" for=""><b>FECHA</b></label>
            </div>
            <div class="w-1/4">
              <label class="text-primary" for="">
                {{ page.order.order_date }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="flex mt-4">
        <div class="w-1/6">
          <label for=""><b>Cliente</b></label>
        </div>
        <div class="w-1/4">
          <label for="">{{ page.order.lname }}</label>
        </div>
        <div class="w-1/6">
          <label for=""><b>Teléfono</b></label>
        </div>
        <div class="w-1/4">
          <label for="">{{ page.order.phoneno }}</label>
        </div>
        <div class="w-1/6">
          <label for=""><b>RUT</b></label>
        </div>
        <div class="w-1/4">
          <label for="">{{ page.order.customer_id }}</label>
        </div>
        <div class="w-1/6">
          <label for=""><b>Dirección</b></label>
        </div>
        <div class="w-1/4">
          <label for="">{{ page.order.address1 }}</label>
        </div>
      </div>
    </div>
    <Panel>
      <table class="w-full divide-y divide-gray-200 table-auto">
        <thead class="bg-black">
          <tr>
            <th
              scope="col"
              class="align-middle px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
            >
              <div class="leading-loose inline-block">Producto</div>
            </th>
            <th
              scope="col"
              class="align-middle px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
            >
              <div class="leading-loose inline-block">Cantidad</div>
            </th>
            <th
              scope="col"
              class="align-middle px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
            >
              <div class="leading-loose inline-block">Unidad</div>
            </th>
            <th
              scope="col"
              class="align-middle px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
            >
              <div class="leading-loose inline-block">Total</div>
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="record in page.order.detail">
            <td class="px-6 py-4 whitespace-nowrap text-sm">
              <img width="100" :src="record.products.image">
              <b>{{ record.products.name }}</b>
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm">
              {{ record.qty }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm">
              {{ record.price }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
              {{ record.price * record.qty }}
            </td>
          </tr>
        </tbody>
      </table>
    </Panel>
    <Modal
      :is-showing="page.isModalCreatevariationShowing"
      @close="page.isModalCreatevariationShowing = false"
    >
      <Panel>
        <Form id="create-variation" @submit.prevent="onSubmitVariation">
          <div class="w-full p-2">
            <TextInput
              class="mb-4"
              type="text"
              :required="true"
              name="name"
              v-model="formNewBanco.name"
              label="Nombre"
            />
          </div>
          <div class="w-full text-right">
            <button
              type="submit"
              class="px-4 py-2 border border-transparent text-sm rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 text-center transition text-white bg-theme-600 hover:bg-theme-800 focus:bg-theme-800 focus:ring-theme-800"
              title="Guardar"
            >
              <i class="mr-1 fa fa-save"></i>
              Guardar
            </button>
          </div>
        </Form>
      </Panel>
    </Modal>
  </Page>
</template>

<script>
import { defineComponent, reactive, onMounted } from 'vue'
import { trans } from '@/helpers/i18n'
import { useAuthStore } from '@/stores/auth'
import Button from '@/views/components/input/Button'
import TextInput from '@/views/components/input/TextInput'
import Dropdown from '@/views/components/input/Dropdown'
import Alert from '@/views/components/Alert'
import Modal from '@/views/components/Modal'
import Panel from '@/views/components/Panel'
import Page from '@/views/layouts/Page'
import FileInput from '@/views/components/input/FileInput'
import SaleService from '@/services/SaleService'
import CategoryService from '@/services/CategoryService'
import SupplierService from '@/services/SupplierService'
import ProductService from '@/services/ProductService'
import { clearObject, reduceProperties } from '@/helpers/data'
import { toUrl } from '@/helpers/routing'
import Form from '@/views/components/Form'
import { useAlertStore } from '@/stores'
import { getResponseError } from '@/helpers/api'
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelSelect } from 'vue-search-select'
import { useRoute } from 'vue-router'
export default defineComponent({
  components: {
    Form,
    FileInput,
    Panel,
    Alert,
    Dropdown,
    TextInput,
    Button,
    Page,
    ModelSelect,
    Modal,
  },
  setup() {
    const { user } = useAuthStore()
    const route = useRoute()
    const alertStore = useAlertStore()
    const form = reactive({
      description: '',
      supplier_id: null,
      total: '',
      order_date: '',
      document_number: '',
      order_time: '',
      status: '1',
      document_number: '',
      detail: [],
    })

    const formPayment = reactive({
      amount: 0,
      operation_number: '',
      sender: '',
      bank_id: null,
    })

    const formDetail = reactive({
      cate_id: null,
      product_id: null,
      product_name: null,
      brand: '',
      tax: '',
      qty: '',
      sub_total: '',
      price: '',
    })
    const formNewBanco = reactive({
      name: null,
    })
    const page = reactive({
      id: 'create_order',
      title: trans('Detalles del pedido'),
      filters: false,
      breadcrumbs: [
        {
          name: trans('global.pages.orders'),
          to: toUrl('/sales/list'),
        },
        {
          name: trans('Detalles del pedido'),
          active: true,
        },
      ],
      actions: [
        {
          id: 'back',
          name: trans('global.buttons.back'),
          icon: 'fa fa-angle-left',
          to: toUrl('/sales/list'),
          theme: 'outline',
        },
      ],
      products: [],
      order: {},
      orderDetail: [],
      bancos: [],
      isModalCreatevariationShowing: false,
    })

    const service = new SaleService()
    const categoryService = new CategoryService()
    const supplierService = new SupplierService()
    const productService = new ProductService()

    function onAction(data) {
      switch (data.action.id) {
        case 'submit':
          onSubmit()
          break
      }
    }
    function getOrder() {
      service
        .edit(route.params.id)
        .then((response) => {
          page.order = response.data.model
          page.order
          console.log('pagte', page.order)
          console.log('response', response)
        })
        .catch((error) => {})
    }

    function getProducts() {
      productService
        .getList()
        .then((response) => {
          page.products = response.data.data
        })
        .catch((error) => {
          alertStore.error(getResponseError(error))
          table.loading = false
        })
    }
    function getBancos() {
      service.getBancos().then((r) => {
        page.bancos = r.data
      })
    }

    function calculateSubTotal() {
      let subTotal = 0
      let unitPrice = formDetail.price
      let qty = formDetail.qty
      subTotal = unitPrice * qty
      if (unitPrice > 0 && qty > 0) {
        this.formDetail.sub_total = subTotal
      }
    }

    function savePayment() {}

    function addDetail() {
      let product = this.page.products.find(
        (item) => item.value == this.formDetail.product,
      )
      let row = {
        product_id: product.value,
        product_name: product.text,
        qty: this.formDetail.qty,
        sub_total: this.formDetail.sub_total,
        price: this.formDetail.price,
      }
      this.page.orderDetail.push(row)
      let total = 0
      this.page.orderDetail.map((item) => {
        total = item.sub_total + total
      })
      this.form.total = total
    }

    function newBanco() {
      page.isModalCreatevariationShowing = true
    }

    function onSubmitVariation() {
      service.crearBanco(reduceProperties(formNewBanco)).then(() => {
        clearObject(formNewBanco)
        getBancos()
        page.isModalCreatevariationShowing = false
      })
      return false
    }

    function onSubmitPayment() {
      if (page.order.total_price == formPayment.amount) {
        formPayment.sale_id = route.params.id
        service.savePayment(reduceProperties(formPayment)).then(() => {
          getOrder()
        })
      }else{
        alertStore.error("El monto debe ser igual al importe de la venta")
      }
    }

    function onSubmit() {
      console.log('this.page.orderDetail', page.orderDetail)
      form.detail = JSON.stringify(reduceProperties(page.orderDetail))
      service
        .handleCreate('create-order', reduceProperties(form, 'id'))
        .then(() => {
          clearObject(form)
        })
      return false
    }
    const statusList = [
      { id: 0, title: 'Active' },
      { id: 1, title: 'Inactive' },
    ]

    onMounted(() => {
      getOrder()
      getBancos()
    })

    return {
      trans,
      user,
      form,
      page,
      onSubmit,
      getProducts,
      getOrder,
      onAction,
      statusList,
      formDetail,
      calculateSubTotal,
      addDetail,
      getBancos,
      newBanco,
      formNewBanco,
      onSubmitVariation,
      onSubmitPayment,
      formPayment,
    }
  },
})
</script>

<style scoped>
.rounded-full {
  padding: 8px;
}
.panel-gray {
  background-color: #f1f1f1;
}
.ml-60 {
  margin-left: 60px;
  margin-bottom: 30px;
}
</style>

import ModelService from "@/services/ModelService";

export default class CategoryService extends ModelService {

    constructor() {
        super();
        this.url = '/product';
    }

    public updateAvatar(id, payload) {
        const formData = new FormData();
        formData.append("avatar", payload.avatar);
        formData.append('_method', 'put');
        return this.post(`/users/${id}/avatar`, formData);
    }

    public getVariations(){
        return this.get(`/variations`);
    }
    public getList(){
        return this.get(`/products-list`);
    }

    public byCategory(category_id){
        return this.get(`product/byCategory/${category_id}`);
    }

    public createNewVariation (data){
        return this.post(`variations`,data);
    }

    public show(product_id){
        return this.get(`product/${product_id}/stock`);
    }

}

<template>
    <div>
        <div type="button" class="inline-flex items-center text-sm leading-6 font-medium rounded-md transition ease-in-out duration-150 cursor-not-allowed" disabled="">
            <p :class="{'block w-full': textNewLine}">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="42px" height="42px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <circle cx="50" cy="50" r="32" stroke-width="8" class="stroke-theme-800" stroke-dasharray="50.26548245743669 50.26548245743669" fill="none" stroke-linecap="round">
                        <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
                    </circle>
                    <circle cx="50" cy="50" r="23" stroke-width="8" class="stroke-theme-500" stroke-dasharray="36.12831551628262 36.12831551628262" stroke-dashoffset="36.12831551628262" fill="none" stroke-linecap="round">
                        <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 50;-360 50 50"></animateTransform>
                    </circle>
                    <!-- [ldio] generated by https://loading.io/ -->
                </svg>
            </p>
            <p :class="{'block w-full': textNewLine}">
                {{ text }}
            </p>
        </div>
    </div>
</template>

<script>
import {defineComponent} from "vue";
import {trans} from "@/helpers/i18n"

export default defineComponent({
    name: "Spinner",
    props: {
        text: {
            type: String,
            required: false,
            default: trans('global.phrases.loading'),
        },
        textNewLine: {
            type: Boolean,
            required: false,
            default: true,
        },
    }
});
</script>

import { default as PageLogin } from '@/views/pages/auth/login/Main'
import { default as PageRegister } from '@/views/pages/auth/register/Main'
import { default as PageResetPassword } from '@/views/pages/auth/reset-password/Main'
import { default as PageForgotPassword } from '@/views/pages/auth/forgot-password/Main'
import { default as PageNotFound } from '@/views/pages/shared/404/Main'

import { default as PageDashboard } from '@/views/pages/private/dashboard/Main'
import { default as PageProfile } from '@/views/pages/private/profile/Main'

import { default as PageUsers } from '@/views/pages/private/users/Index'
import { default as PageUsersCreate } from '@/views/pages/private/users/Create'
import { default as PageUsersEdit } from '@/views/pages/private/users/Edit'

import { default as PageCategory } from '@/views/pages/private/category/Index'
import { default as PageCategoryCreate } from '@/views/pages/private/category/Create'
import { default as PageCategoryEdit } from '@/views/pages/private/category/Edit'

import { default as PageCoupon } from '@/views/pages/private/coupon/Index'
import { default as PageCouponCreate } from '@/views/pages/private/coupon/Create'
import { default as PageCouponEdit } from '@/views/pages/private/coupon/Edit'

import { default as PageProduct } from '@/views/pages/private/product/Index'
import { default as PageProductCreate } from '@/views/pages/private/product/Create'
import { default as PageProductEdit } from '@/views/pages/private/product/Edit'

import { default as PageShopping } from '@/views/pages/private/shopping/Index'
import { default as PageShoppingCreate } from '@/views/pages/private/shopping/Create'
import { default as PageShoppingEdit } from '@/views/pages/private/shopping/Edit'

import { default as PageOrder } from '@/views/pages/private/order/Index'
import { default as PageOrderCreate } from '@/views/pages/private/order/Create'
import { default as PageOrderEdit } from '@/views/pages/private/order/Edit'
import { default as PageOrderShow } from '@/views/pages/private/order/Show'

import { default as PageSales } from '@/views/pages/private/sales/Index'
import { default as PageSalesCreate } from '@/views/pages/private/sales/Create'
import { default as PageSalesEdit } from '@/views/pages/private/sales/Edit'
import { default as PageSalesShow } from '@/views/pages/private/sales/Show'

import { default as PageKardex } from '@/views/pages/private/kardex/Index'
import { default as PageKardexDetail } from '@/views/pages/private/kardex/Detail'

import { default as PageRole } from '@/views/pages/private/role/Edit'

import { default as PageReportProduct } from '@/views/pages/private/report/product/Product'
import { default as PageReportCustomer } from '@/views/pages/private/report/customer/Customer'
import { default as PageReportRegion } from '@/views/pages/private/report/region/Region'

import { default as PageSupplier } from '@/views/pages/private/supplier/Index'
import { default as PageSupplierCreate } from '@/views/pages/private/supplier/Create'
import { default as PageSupplierEdit } from '@/views/pages/private/supplier/Edit'

import { default as PageCustomer } from '@/views/pages/private/customer/Index'
import { default as PageCustomerCreate } from '@/views/pages/private/customer/Create'
import { default as PageCustomerEdit } from '@/views/pages/private/customer/Edit'

import { default as PageBanner } from '@/views/pages/private/banner/Index'
import { default as PageBannerCreate } from '@/views/pages/private/banner/Create'
import { default as PageBannerEdit } from '@/views/pages/private/banner/Edit'

import abilities from '@/stub/abilities'

const routes = [
  {
    name: 'home',
    path: '/',
    meta: { requiresAuth: false },
    component: PageLogin,
  },
  {
    name: 'panel',
    path: '/panel',
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        meta: { requiresAuth: true},
        component: PageDashboard,
      },
      {
        path: 'users',
        children: [
          {
            name: 'users.default',
            path: '',
            meta: {
              requiresAuth: true,
              requiresAbility: abilities.LIST_USER
            },
            component: PageUsers,
          },
          {
            name: 'users.list',
            path: 'list',
            meta: {
              requiresAuth: true,
              requiresAbility: abilities.LIST_USER
            },
            component: PageUsers,
          },
          {
            name: 'users.create',
            path: 'create',
            meta: {
              requiresAuth: true,
              requiresAbility: abilities.CREATE_USER,
            },
            component: PageUsersCreate,
          },
          {
            name: 'users.edit',
            path: ':id/edit',
            meta: {
              requiresAuth: true,
              requiresAbility: abilities.EDIT_USER
            },
            component: PageUsersEdit,
          },
        ],
      },
      {
        path: 'category',
        children: [
          {
            name: 'category.default',
            path: '',
            meta: { requiresAuth: true },
            component: PageCategory,
          },
          {
            name: 'category.list',
            path: 'list',
            meta: { requiresAuth: true },
            component: PageCategory,
          },
          {
            name: 'category.create',
            path: 'create',
            meta: { requiresAuth: true },
            component: PageCategoryCreate,
          },
          {
            name: 'category.edit',
            path: ':id/edit',
            meta: { requiresAuth: true },
            component: PageCategoryEdit,
          },
          
        ],
      },
      {
        path: 'banner',
        children: [
          {
            name: 'banner.default',
            path: '',
            meta: { requiresAuth: true },
            component: PageBanner,
          },
          {
            name: 'banner.list',
            path: 'list',
            meta: { requiresAuth: true },
            component: PageBanner,
          },
          {
            name: 'banner.create',
            path: 'create',
            meta: { requiresAuth: true },
            component: PageBannerCreate,
          },
          {
            name: 'banner.edit',
            path: ':id/edit',
            meta: { requiresAuth: true },
            component: PageBannerEdit,
          },
          
        ],
      },
      {
        path: 'coupon',
        children: [
          {
            name: 'coupon.default',
            path: '',
            meta: { requiresAuth: true },
            component: PageCoupon,
          },
          {
            name: 'coupon.list',
            path: 'list',
            meta: { requiresAuth: true },
            component: PageCoupon,
          },
          {
            name: 'coupon.create',
            path: 'create',
            meta: { requiresAuth: true },
            component: PageCouponCreate,
          },
          {
            name: 'coupon.edit',
            path: ':id/edit',
            meta: { requiresAuth: true },
            component: PageCouponEdit,
          },
          
        ],
      },
      {
        path: 'product',
        children: [
          {
            name: 'product.default',
            path: '',
            meta: { requiresAuth: true },
            component: PageProduct,
          },
          {
            name: 'product.list',
            path: 'list',
            meta: { requiresAuth: true },
            component: PageProduct,
          },
          {
            name: 'product.create',
            path: 'create',
            meta: { requiresAuth: true },
            component: PageProductCreate,
          },
          {
            name: 'product.edit',
            path: ':id/edit',
            meta: { requiresAuth: true },
            component: PageProductEdit,
          },
        ],
      },
      {
        path: 'supplier',
        children: [
          {
            name: 'supplier.default',
            path: '',
            meta: { requiresAuth: true },
            component: PageSupplier,
          },
          {
            name: 'supplier.list',
            path: 'list',
            meta: { requiresAuth: true },
            component: PageSupplier,
          },
          {
            name: 'supplier.create',
            path: 'create',
            meta: { requiresAuth: true },
            component: PageSupplierCreate,
          },
          {
            name: 'supplier.edit',
            path: ':id/edit',
            meta: { requiresAuth: true },
            component: PageSupplierEdit,
          },
        ],
      },
      {
        path: 'customer',
        children: [
          {
            name: 'customer.default',
            path: '',
            meta: { requiresAuth: true },
            component: PageCustomer,
          },
          {
            name: 'customer.list',
            path: 'list',
            meta: { requiresAuth: true },
            component: PageCustomer,
          },
          {
            name: 'customer.create',
            path: 'create',
            meta: { requiresAuth: true },
            component: PageCustomerCreate,
          },
          {
            name: 'customer.edit',
            path: ':id/edit',
            meta: { requiresAuth: true },
            component: PageCustomerEdit,
          },
        ],
      },
      {
        path: 'shopping',
        children: [
          {
            name: 'shopping.default',
            path: '',
            meta: { requiresAuth: true },
            component: PageShopping,
          },
          {
            name: 'shopping.list',
            path: 'list',
            meta: { requiresAuth: true },
            component: PageShopping,
          },
          {
            name: 'shopping.create',
            path: 'create',
            meta: { requiresAuth: true },
            component: PageShoppingCreate,
          },
          {
            name: 'shopping.edit',
            path: ':id/edit',
            meta: { requiresAuth: true },
            component: PageShoppingEdit,
          },
        ],
      },
      {
        path: 'order',
        children: [
          {
            name: 'order.default',
            path: '',
            meta: { requiresAuth: true },
            component: PageOrder,
          },
          {
            name: 'order.list',
            path: 'list',
            meta: { requiresAuth: true },
            component: PageOrder,
          },
          {
            name: 'order.create',
            path: 'create',
            meta: { requiresAuth: true },
            component: PageOrderCreate,
          },
          {
            name: 'order.edit',
            path: ':id/edit',
            meta: { requiresAuth: true },
            component: PageOrderEdit,
          },
          {
            name: 'order.show',
            path: ':id/show',
            meta: { requiresAuth: true },
            component: PageOrderShow,
          },
        ],
      },
      {
        path: 'sales',
        children: [
          {
            name: 'sales.default',
            path: '',
            meta: { requiresAuth: true },
            component: PageSales,
          },
          {
            name: 'sales.list',
            path: 'list',
            meta: { requiresAuth: true },
            component: PageSales,
          },
          {
            name: 'sales.create',
            path: 'create',
            meta: { requiresAuth: true },
            component: PageSalesCreate,
          },
          {
            name: 'sales.edit',
            path: ':id/edit',
            meta: { requiresAuth: true },
            component: PageSalesEdit,
          },
          {
            name: 'sales.show',
            path: ':id/show',
            meta: { requiresAuth: true },
            component: PageSalesShow,
          },
        ],
      },
      {
        path: 'report',
        children: [
          {
            name: 'report.product',
            path: 'product',
            meta: { requiresAuth: true },
            component: PageReportProduct,
          },
          {
            name: 'report.customer',
            path: 'customer',
            meta: { requiresAuth: true },
            component: PageReportCustomer,
          },
          {
            name: 'report.region',
            path: 'region',
            meta: { requiresAuth: true },
            component: PageReportRegion,
          },
        ],
      },
      {
        name: 'stock',
        path: 'stock',
        meta: { requiresAuth: true},
        component: PageKardex,
      },
      {
        name: 'roles',
        path: 'roles',
        meta: { requiresAuth: true},
        component: PageRole,
      },
      {
        name: 'stock-detail',
        path: 'stock/:id/detail',
        meta: { requiresAuth: true},
        component: PageKardexDetail,
      },
      {
        name: 'profile',
        path: 'profile',
        meta: { requiresAuth: true, isOwner: true },
        component: PageProfile,
      },
      
    ],
  },
  {
    path: '/login',
    name: 'login',
    meta: { requiresAuth: false },
    component: PageLogin,
  },
  {
    path: '/register',
    name: 'register',
    meta: { requiresAuth: false },
    component: PageRegister,
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    meta: { requiresAuth: false },
    component: PageResetPassword,
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    meta: { requiresAuth: false },
    component: PageForgotPassword,
  },
  {
    path: '/:catchAll(.*)',
    name: 'notFound',
    meta: { requiresAuth: false },
    component: PageNotFound,
  },
]

export default routes

<template>
  <Page
    :title="page.title"
    :breadcrumbs="page.breadcrumbs"
    :actions="page.actions"
    @action="onAction"
    class="w-full m-5"
  >
    <template #default>
      <Table
        :id="page.id"
        v-if="table"
        :headers="table.headers"
        :sorting="table.sorting"
        :records="table.records"
        :actions="null"
        :pagination="table.pagination"
        :is-loading="table.loading"
        @page-changed="onTablePageChange"
        @action="onTableAction"
        @sort="onTableSort"
      >
        <template v-slot:content-id="props">
          <div class="flex items-center">
            <div class="ml-4">
              <div class="text-sm font-medium text-gray-900">
                {{ props.item.full_name }}
              </div>
              <div class="text-sm text-gray-500">
                {{ trans('users.labels.id') + ': ' + props.item.id }}
              </div>
            </div>
          </div>
        </template>
        <template v-slot:content-role="props">
          {{
            props.item.roles
              .map((entry) => {
                return entry.title
              })
              .join(', ')
          }}
        </template>
      </Table>
    </template>
  </Page>
</template>

<script>
import { defineComponent,watch, onMounted , reactive, onBeforeMount } from 'vue'
import { getResponseError, prepareQuery } from '@/helpers/api'
import { trans } from '@/helpers/i18n'
import { useAuthStore } from '@/stores/auth'
import { useRoute } from 'vue-router'
import Button from '@/views/components/input/Button'
import TextInput from '@/views/components/input/TextInput'
import Table from '@/views/components/Table'
import Dropdown from '@/views/components/input/Dropdown'
import Alert from '@/views/components/Alert'
import Panel from '@/views/components/Panel'
import Page from '@/views/layouts/Page'
import FileInput from '@/views/components/input/FileInput'
import ProductService from '@/services/ProductService'
import { clearObject, fillObject, reduceProperties } from '@/helpers/data'
import { toUrl } from '@/helpers/routing'
import Form from '@/views/components/Form'

export default defineComponent({
  components: {
    Form,
    FileInput,
    Panel,
    Alert,
    Dropdown,
    TextInput,
    Button,
    Table,
    Page,
  },
  setup() {
    const { user } = useAuthStore()
    const route = useRoute()
    const form = reactive({
      name: '',
      slug: '',
      description: '',
      image: '',
      status: '',
    })
    const mainQuery = reactive({
      page: 1,
      search: '',
      sort: '',
    })
    const page = reactive({
      id: 'edit_users',
      title: 'Detalle de Existencias',
      filters: false,
      breadcrumbs: [
        {
          name: 'Existencias',
          to: toUrl('/stock/list'),
        },
        {
          name: 'Detalle de Existencia',
          active: true,
        },
      ],
      headers: {
        id: trans('product.labels.id'),
        sku: trans('product.labels.sku'),
        qty: trans('product.labels.qty'),
        name: trans('product.labels.name'),
        status: trans('product.labels.status'),
      },
      sorting: {
      },
      pagination: {
        meta: null,
        links: null,
      },
      loading: false,
      records: null,
      actions: [
        {
          id: 'back',
          name: trans('global.buttons.back'),
          icon: 'fa fa-angle-left',
          to: toUrl('/stock'),
          theme: 'outline',
        },
    ]
    })

    const table = reactive({
      headers: {
        id: trans('kardex.labels.id'),
        document_number: trans('kardex.labels.document_number'),
        movement_date: trans('kardex.labels.movement_date'),
        movement_type: trans('kardex.labels.movement_type'),
        qty: trans('kardex.labels.qty'),
        unit_price: trans('kardex.labels.unit_price'),
        description:trans('kardex.labels.description'),
      },
      sorting: {
        first_name: true,
        last_name: true,
      },
      pagination: {
        meta: null,
        links: null,
      },
      actions: {
        
      },
      loading: false,
      records: null,
    })

    const service = new ProductService()

    function onAction(data) {}

    function onTableSort(params) {
      mainQuery.sort = params
    }

    function onTablePageChange(page) {
      mainQuery.page = page
    }

    function fetchPage(params) {
      table.records = []
      table.loading = true
      let query = prepareQuery(params)
      service
        .show(route.params.id)
        .then((response) => {
          table.records = response.data.data
          table.pagination.meta = response.data.meta
          table.pagination.links = response.data.links
          table.loading = false
        })
        .catch((error) => {
          alertStore.error(getResponseError(error))
          table.loading = false
        })
    }
    watch(mainQuery, (newTableState) => {
      fetchPage(mainQuery)
    })

    onMounted(() => {
      fetchPage(mainQuery)
    })

    onBeforeMount(() => {
      service.show(route.params.id).then((response) => {
        fillObject(form, response.data.model)
        page.loading = false
      })
    })

    const roles = [{ id: 1, name: 2 }]

    return {
      trans,
      user,
      page,
      onAction,
      table,
      onTablePageChange,
      onTableSort,
      mainQuery,
      roles,
    }
  },
})
</script>

<style scoped></style>

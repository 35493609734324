<template>
    <Page :title="page.title" :breadcrumbs="page.breadcrumbs" :actions="page.actions" @action="onAction" class="w-full m-5">
        <Panel>
            <Form id="create-supplier" @submit.prevent="onSubmit">
                <TextInput
          class="mb-4"
          type="text"
          :required="true"
          name="name"
          v-model="form.code"
          :label="'Codigo'" />
        <TextInput
          class="mb-4"
          type="text"
          :required="true"
          name="slug"
          v-model="form.name"
          :label="'Nombres'"
        />
        <TextInput
          class="mb-4"
          type="text"
          :required="true"
          name="rut"
          v-model="form.rut"
          :label="'RUT'"
        />
        <TextInput
          class="mb-4"
          type="text"
          :required="false"
          name="email"
          v-model="form.email"
          :label="'Correo electrónico'"
        />
        <TextInput
          class="mb-4"
          type="text"
          :required="true"
          name="phone"
          v-model="form.phone"
          :label="'Teléfono'"
        />
        <TextInput
          class="mb-4"
          type="text"
          :required="true"
          name="address"
          v-model="form.address"
          :label="'Dirección'"
        />
            </Form>
        </Panel>
    </Page>
  </template>
  
  <script>
  import {defineComponent, reactive,onBeforeMount } from "vue";
  import {trans} from "@/helpers/i18n";
  import {useAuthStore} from "@/stores/auth";
  import {useRoute} from "vue-router";
  import Button from "@/views/components/input/Button";
  import TextInput from "@/views/components/input/TextInput";
  import Dropdown from "@/views/components/input/Dropdown";
  import Alert from "@/views/components/Alert";
  import Panel from "@/views/components/Panel";
  import Page from "@/views/layouts/Page";
  import FileInput from "@/views/components/input/FileInput";
  import SupplierService from "@/services/SupplierService";
  import {clearObject,fillObject, reduceProperties} from "@/helpers/data";
  import {toUrl} from "@/helpers/routing";
  import Form from "@/views/components/Form";
  
  export default defineComponent({
    components: {Form, FileInput, Panel, Alert, Dropdown, TextInput, Button, Page},
    setup() {
        const {user} = useAuthStore();
        const route = useRoute();
        const form = reactive({
            name: '',
            address: '',
            rut: '',
            email: '',
            phone: '',
            code: '',
        });
  
        const page = reactive({
            id: 'edit_users',
            title: trans('global.pages.supplier_edit'),
            filters: false,
            breadcrumbs: [
                {
                    name: trans('global.pages.suppliers'),
                    to: toUrl('/supplier/list'),
  
                },
                {
                    name: trans('global.pages.supplier_edit'),
                    active: true,
                }
            ],
            actions: [
                {
                    id: 'back',
                    name: trans('global.buttons.back'),
                    icon: "fa fa-angle-left",
                    to: toUrl('/supplier/list'),
                    theme: 'outline',
                },
                {
                    id: 'submit',
                    name: trans('global.buttons.save'),
                    icon: "fa fa-save",
                    type: 'submit',
                }
            ]
        });
  
        const service = new SupplierService();
  
        function onAction(data) {
            switch(data.action.id) {
                case 'submit':
                    onSubmit();
                    break;
            }
        }

        onBeforeMount(() => {
            service.edit(route.params.id).then((response) => {
                fillObject(form, response.data.model);
                page.loading = false;
            })
        });
     
        function onSubmit() {
            service.handleUpdate('edit-supplier', route.params.id, reduceProperties(form, 'roles', 'id'));
            return false;
        }
  
        const roles = [{id:1,name:2}]
  
        return {
            trans,
            user,
            form,
            page,
            onSubmit,
            onAction,
            roles
        }
    }
  })
  </script>
  
  <style scoped>
  
  </style>
  
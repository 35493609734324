<template>
  <Page :title="page.title" :breadcrumbs="page.breadcrumbs" :actions="page.actions" @action="onAction"
    class="w-full m-5">
    <Panel>
      <Form id="create-shopping" @submit.prevent="onSubmit">
        <div class="flex">
          <div class="w-1/3 p-2">
            <label :for="name" class="text-sm text-gray-500">
              Proveedor
              <span class="text-red-600">*</span>
            </label>
            <select
              class="mb-4 block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-theme-500 focus:border-theme-500 text-sm"
              :required="true" name="supplier_id" v-model="form.supplier_id" :label="trans('shopping.labels.supplier')">
              <option :value="null">Seleccione un proveedor</option>
              <option v-for="item in page.suppliers" :value="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="w-1/4 p-2">
            <TextInput class="mb-4" type="text" :required="true" name="document_number" v-model="form.document_number"
              :label="trans('shopping.labels.document_number')" />
          </div>
          <div class="w-1/4 p-2">
            <TextInput class="mb-4" type="date" :required="true" name="shopping_date" v-model="form.shopping_date"
              :label="trans('shopping.labels.shopping_date')" />
          </div>
          <!-- <div class="w-1/4 p-2">
            <TextInput class="mb-4" type="time" :required="true" name="shopping_time" v-model="form.shopping_time"
              :label="trans('shopping.labels.shopping_time')" />
          </div> -->
          <div class="w-1/4 p-2">
            <TextInput class="mb-4" type="text" :required="true" name="total" :disabled="true" v-model="form.total"
              :label="trans('shopping.labels.total')" />
          </div>
          <div class="w-1/4 p-2">
            <label :for="name" class="text-sm text-gray-500">
              Estado
              <span class="text-red-600">*</span>
            </label>
            <select
              class="mb-4 block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-theme-500 focus:border-theme-500 text-sm"
              :required="true" name="status" v-model="form.status" :label="trans('shopping.labels.status')">
              <option value="1">Activo</option>
              <option value="0">Desactivado</option>
            </select>
          </div>
        </div>
        <div class="flex">
          <div class="w-full p-2">
            <TextInput class="mb-4" type="textarea" :required="true" name="description" v-model="form.description"
              :label="trans('shopping.labels.description')" />
          </div>
        </div>

        <Panel>
          <h3>Detalle</h3>
          <br />
          <div class="flex">
            <div class="w-1/3 p-2">
              <label :for="name" class="text-sm text-gray-500">
                Categoría
                <span class="text-red-600">*</span>
              </label>
              <select
                class="mb-4 block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-theme-500 focus:border-theme-500 text-sm"
                :required="true" name="cate_id" @change="getProducts()" v-model="formDetail.category"
                :label="trans('shopping.labels.category')">
                <option :value="null">Seleccione una categoría</option>
                <option v-for="item in page.categories" :value="item">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="w-1/3 p-2">
              <label :for="name" class="text-sm text-gray-500">
                Producto
                <span class="text-red-600">*</span>
              </label>
              <model-select :options="page.products" v-model="formDetail.product"
                placeholder="Seleccione un producto"></model-select>
            </div>
            <div class="w-1/3 p-2">
              <TextInput class="mb-4" type="text" :required="true" name="brand" v-model="formDetail.brand"
                :label="trans('shopping.labels.brand')" />
            </div>
          </div>
          <div class="flex">
            <div class="w-1/6 p-2">
              <TextInput class="mb-4" type="text" :required="true" name="tax" v-model="formDetail.tax"
                :label="trans('shopping.labels.tax')" />
            </div>
            <div class="w-1/6 p-2">
              <label class="text-sm text-gray-500">
                {{ trans('shopping.labels.qty') }}
                <span class="text-red-600">*</span>
              </label>

              <input type="text" :required="true" name="qty" @input="calculateSubTotal()" v-model="formDetail.qty"
                class="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-theme-500 focus:border-theme-500 text-sm" />
            </div>
            <div class="w-1/6 p-2">
              <label class="text-sm text-gray-500">
                {{ trans('shopping.labels.unit_price') }}
                <span class="text-red-600">*</span>
              </label>
              <input type="text" :required="true" name="unit_price" @input="calculateSubTotal()"
                v-model="formDetail.unit_price"
                class="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-theme-500 focus:border-theme-500 text-sm" />
            </div>
            <div class="w-1/6 p-2">
              <TextInput class="mb-4" type="text" :required="true" name="sub_total" v-model="formDetail.sub_total"
                :disabled="true" :label="trans('shopping.labels.sub_total')" />
            </div>
            <div class="w-1/6 p-2">
              <br />
              <a @click="addDetail()"
                class="px-4 py-2 border border-transparent text-sm rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 text-center transition text-white bg-theme-600 hover:bg-theme-800 focus:bg-theme-800 focus:ring-theme-800">
                <i class="mr-1 fa fa-plus"></i>
                Agregar
              </a>
            </div>
          </div>
          <div>
            <div class="w-full shadow border-b border-gray-200 mb-8 sm:rounded-lg">
              <table class="w-full divide-y divide-gray-200 table-auto">
                <thead class="bg-gray-50">
                  <tr>
                    <!-- <th scope="col"
                      class="align-middle px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <div class="leading-loose inline-block">Categoría</div>
                    </th> -->
                    <th scope="col"
                      class="align-middle px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <div class="leading-loose inline-block">Producto</div>
                    </th>
                    <th scope="col"
                      class="align-middle px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <div class="leading-loose inline-block">Marca</div>
                    </th>
                    <th scope="col"
                      class="align-middle px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <div class="leading-loose inline-block">Tax</div>
                    </th>
                    <th scope="col"
                      class="align-middle px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <div class="leading-loose inline-block">Cantidad</div>
                    </th>
                    <th scope="col"
                      class="align-middle px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <div class="leading-loose inline-block">
                        Precio Unitario
                      </div>
                    </th>
                    <th scope="col"
                      class="align-middle px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <div class="leading-loose inline-block">Sub total</div>
                    </th>
                    <th scope="col"
                      class="align-middle px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Acciones
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="record in form.detail">
                    <!-- <td class="px-6 py-4 whitespace-nowrap text-sm">
                      {{ record.category_name }}
                    </td> -->
                    <td class="px-6 py-4 whitespace-nowrap text-sm">
                      {{ record.product_name }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm">
                      {{ record.brand }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm">
                      {{ record.tax }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm">
                      {{ record.qty }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm">
                      {{ record.unit_price }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm">
                      {{ record.sub_total }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <a @click="deleteRecord(record)" class="uppercase cursor-pointer text-lg mr-3 text-danger-400"
                        title="Delete">
                        <i class="fa fa-trash"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Panel>
      </Form>
    </Panel>
  </Page>
</template>

<script>
import { defineComponent, reactive, onMounted, onBeforeMount } from 'vue'
import { trans } from '@/helpers/i18n'
import { useAuthStore } from '@/stores/auth'
import Button from '@/views/components/input/Button'
import TextInput from '@/views/components/input/TextInput'
import Dropdown from '@/views/components/input/Dropdown'
import Alert from '@/views/components/Alert'
import Panel from '@/views/components/Panel'
import Page from '@/views/layouts/Page'
import FileInput from '@/views/components/input/FileInput'
import ShoppingService from '@/services/ShoppingService'
import CategoryService from '@/services/CategoryService'
import SupplierService from '@/services/SupplierService'
import ProductService from '@/services/ProductService'
import { clearObject, reduceProperties, fillObject } from '@/helpers/data'
import { toUrl } from '@/helpers/routing'
import Form from '@/views/components/Form'
import { useAlertStore } from '@/stores'
import { getResponseError } from '@/helpers/api'
// import { useRoute } from "vue-router";

import { useRoute, useRouter } from 'vue-router';

import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelSelect } from 'vue-search-select'

export default defineComponent({
  components: {
    Form,
    FileInput,
    Panel,
    Alert,
    Dropdown,
    TextInput,
    Button,
    Page,
    ModelSelect
  },
  setup() {
    const { user } = useAuthStore()
    const route = useRoute();
    const router = useRouter();

    const form = reactive({
      description: '',
      supplier_id: null,
      document_number: null,
      total: '',
      shopping_date: '',
      shopping_time: '',
      status: '1',
      detail: []
    })

    const formDetail = reactive({
      cate_id: null,
      category_name: null,
      product_id: null,
      product_name: null,
      brand: '',
      tax: '',
      qty: '',
      sub_total: '',
      unit_price: ''
    })

    const page = reactive({
      id: 'edit_shopping',
      title: trans('global.pages.shopping_edit'),
      filters: false,
      breadcrumbs: [
        {
          name: trans('global.pages.shoppings'),
          to: toUrl('/shopping/list'),
        },
        {
          name: trans('global.pages.shopping_edit'),
          active: true,
        },
      ],
      actions: [
        {
          id: 'back',
          name: trans('global.buttons.back'),
          icon: 'fa fa-angle-left',
          to: toUrl('/shopping/list'),
          theme: 'outline',
        },
        {
          id: 'submit',
          name: trans('global.buttons.save'),
          icon: 'fa fa-save',
          type: 'submit',
        },
      ],
      categories: [],
      products: [],
      suppliers: [],
    })

    const service = new ShoppingService()
    const categoryService = new CategoryService()
    const supplierService = new SupplierService()
    const productService = new ProductService()

    function onAction(data) {
      switch (data.action.id) {
        case 'submit':
          onSubmit()
          break
      }
    }

    function getSuppliers() {
      supplierService
        .index()
        .then((response) => {
          page.suppliers = response.data.data
        })
        .catch((error) => {
          alertStore.error(getResponseError(error))
          table.loading = false
        })
    }

    function getCategories() {
      categoryService
        .index()
        .then((response) => {
          page.categories = response.data.data
        })
        .catch((error) => {
          alertStore.error(getResponseError(error))
          table.loading = false
        })
    }
    function getProducts() {
      productService
        .byCategory(formDetail.category.id)
        .then((response) => {
          page.products = response.data.data
        })
        .catch((error) => {
          alertStore.error(getResponseError(error))
          table.loading = false
        })
    }

    function calculateSubTotal() {
      let subTotal = 0
      let unitPrice = formDetail.unit_price
      let qty = formDetail.qty
      subTotal = unitPrice * qty
      if (unitPrice > 0 && qty > 0) {
        this.formDetail.sub_total = subTotal
      }
    }

    function addDetail() {
      let product = this.page.products.find(
        (item) => item.value == this.formDetail.product,
      )
      this.formDetail.cate_id = this.formDetail.category.id
      let row = {
        cate_id: this.formDetail.category.id,
        category_name: this.formDetail.category.name,
        product_id: product.value,
        product_name: product.text,
        brand: this.formDetail.brand,
        tax: this.formDetail.tax,
        qty: this.formDetail.qty,
        sub_total: this.formDetail.sub_total,
        unit_price: this.formDetail.unit_price,
      }
      this.form.detail.push(row)
      let total = 0
      this.form.detail.map((item) => {
        total = item.sub_total + total
      })
      this.form.total = total
    }
    function deleteRecord(item) {
      let idx = this.form.detail.indexOf(item)
      this.form.detail.splice(idx, 1)
      let total = 0
      this.form.detail.map((item) => {
        total = (item.price * item.qty) + total
      })
      this.form.total = total
    }

    // function onSubmit() {
    //   form.detail = JSON.stringify(reduceProperties(form.detail));
    //   service
    //     .handleUpdate('edit-shopping', route.params.id, reduceProperties(form, 'id'))
    //     .then(() => {
    //       clearObject(form)
    //     })
    //   return false
    // }


    function onSubmit() {
      form.detail = JSON.stringify(reduceProperties(form.detail));

      service.handleUpdate('edit-shopping', route.params.id, reduceProperties(form, 'id'))
        .then(() => {
          clearObject(form);
          console.log("Redirigiendo a la ruta 'shopping.list'");
          router.push({ name: 'shopping.list' });
        })
        .catch((error) => {
          console.error('Error al actualizar:', error);
        });

      return false; // Para prevenir el comportamiento predeterminado del formulario
    }




    const statusList = [
      { id: 0, title: 'Active' },
      { id: 1, title: 'Inactive' },
    ]

    onBeforeMount(() => {
      service.edit(route.params.id).then((response) => {
        fillObject(form, response.data.model);
        page.loading = false;
      })
    });

    onMounted(() => {
      getCategories()
      getSuppliers()
    })

    return {
      trans,
      user,
      form,
      page,
      onSubmit,
      getProducts,
      onAction,
      statusList,
      formDetail,
      calculateSubTotal,
      addDetail,
      deleteRecord
    }
  },
})
</script>

<style scoped></style>
import ModelService from "@/services/ModelService";

export default class CustomerService extends ModelService {

    constructor() {
        super();
        this.url = '/customer';
    }
    public getList(){
        return this.get(`/customers-list`);
    }
}

<template>
  <div class="h-screen flex flex-col items-center justify-center">
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { trans } from '@/helpers/i18n'

export default defineComponent({
  name: 'Auth',
  setup() {
    let AppConfig = window.AppConfig

    return {
      trans,
      AppConfig,
    }
  },
})
</script>

<template>
  <Page :title="page.title" :breadcrumbs="page.breadcrumbs" :actions="page.actions" @action="onAction"
    class="w-full m-5">
    <Panel>
      <Form id="create-category" @submit.prevent="onSubmit">
        <div class="flex flex-wrap">
          <!-- Nombre y Descripción en la misma línea -->
          <div class="w-1/2 p-2">
            <TextInput class="mb-4" type="text" :required="true" name="name" v-model="form.name"
              :label="trans('coupon.labels.name')" />
          </div>
          <div class="w-1/2 p-2">
            <TextInput class="mb-4" type="text" :required="true" name="description" v-model="form.description"
              :label="trans('coupon.labels.description')" />
          </div>

          <!-- Los otros datos en la siguiente línea divididos en cuatro columnas -->
          <div class="w-1/4 p-2">
            <TextInput class="mb-4" type="number" :required="true" name="discount" v-model="form.discount"
              :label="trans('coupon.labels.discount')" />
          </div>
          <div class="w-1/4 p-2">
            <TextInput class="mb-4" type="date" :required="true" name="start_date" v-model="form.start_date"
              :label="trans('coupon.labels.start_date')" />
          </div>
          <div class="w-1/4 p-2">
            <TextInput class="mb-4" type="date" :required="true" name="end_date" v-model="form.end_date"
              :label="trans('coupon.labels.end_date')" />
          </div>
          <div class="w-1/4 p-2">
            <label :for="name" class="text-sm text-gray-500">
              Estado
              <span class="text-red-600">*</span>
            </label>
            <select
              class="mb-4 block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-theme-500 focus:border-theme-500 text-sm"
              :required="true" name="status" v-model="form.status" :label="trans('product.labels.status')">
              <option value="1">Activo</option>
              <option value="0">Desactivado</option>
            </select>
          </div>
          <div class="w-1/4 p-2">
            <label :for="name" class="text-sm text-gray-500">
              Proveedor
              <span class="text-red-600">*</span>
            </label>
            <select
              class="mb-4 block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-theme-500 focus:border-theme-500 text-sm"
              :required="true" name="is_proveedor" v-model="form.is_proveedor" :label="trans('product.labels.is_proveedor')">
              <option value="1">Si</option>
              <option value="0">No</option>
            </select>
          </div>
        </div>
      </Form>
    </Panel>
  </Page>
</template>

<script>
import { defineComponent, reactive, onBeforeMount } from 'vue'
import { trans } from '@/helpers/i18n'
import { useAuthStore } from '@/stores/auth'
import { useRoute } from 'vue-router'
import Button from '@/views/components/input/Button'
import TextInput from '@/views/components/input/TextInput'
import Dropdown from '@/views/components/input/Dropdown'
import Alert from '@/views/components/Alert'
import Panel from '@/views/components/Panel'
import Page from '@/views/layouts/Page'
import FileInput from '@/views/components/input/FileInput'
import CouponService from '@/services/CouponService'
import { clearObject, fillObject, reduceProperties } from '@/helpers/data'
import { toUrl } from '@/helpers/routing'
import Form from '@/views/components/Form'

export default defineComponent({
  components: {
    Form,
    FileInput,
    Panel,
    Alert,
    Dropdown,
    TextInput,
    Button,
    Page,
  },
  setup() {
    const { user } = useAuthStore()
    const route = useRoute()
    const form = reactive({
      name: '',
      description: '',
      discount: '',
      start_date: '',
      end_date: '',
      status: '',
      is_proveedor: '',
      popular: false
    })

    const page = reactive({
      id: 'edit_users',
      title: trans('global.pages.coupon_edit'),
      filters: false,
      breadcrumbs: [
        {
          name: trans('global.pages.coupon'),
          to: toUrl('/category/list'),
        },
        {
          name: trans('global.pages.coupon_edit'),
          active: true,
        },
      ],
      actions: [
        {
          id: 'back',
          name: trans('global.buttons.back'),
          icon: 'fa fa-angle-left',
          to: toUrl('/coupon/list'),
          theme: 'outline',
        },
        {
          id: 'submit',
          name: trans('global.buttons.save'),
          icon: 'fa fa-save',
          type: 'submit',
        },
      ],
    })

    const service = new CouponService()

    function onAction(data) {
      switch (data.action.id) {
        case 'submit':
          onSubmit()
          break
      }
    }

    onBeforeMount(() => {
      service.edit(route.params.id).then((response) => {
        if (response.data.model.popular == 1) {
          response.data.model.popular = true;
        }
        fillObject(form, response.data.model)
        console.log("form", form)
        page.loading = false
      })
    })

    function onSubmit() {
      service.handleUpdate(
        'edit-coupon',
        route.params.id,
        reduceProperties(form, 'roles', 'id'),
      )
      return false
    }
    function onFileChange(e) {
      const file = e.target.files[0]
      let url = URL.createObjectURL(file)
        ; (form.image = file), (form.imageSrc = url)
    }
    const roles = [{ id: 1, name: 2 }]

    return {
      trans,
      user,
      form,
      page,
      onFileChange,
      onSubmit,
      onAction,
      roles,
    }
  },
})
</script>

<style scoped></style>
<template>
    <Page>
        <div class="m-auto text-center pt-10">
            <h1 class="text-6xl mb-4 font-bold text-gray-600">Sistema de Gestión de productos</h1>
            <br>
            <button class="relative z-10 w-70 h-70 rounded-full">
                            <img src="/assets/images/logo.png">
            </button>
        </div>
    </Page>
</template>

<script>
import {defineComponent} from 'vue';
import {trans} from "@/helpers/i18n";
import Page from "@/views/layouts/Page";

export default defineComponent({
    components: {
        Page
    },
    setup() {
        return {
            trans
        }
    }
});
</script>

<template>
  <Page :title="page.title" :breadcrumbs="page.breadcrumbs" :actions="page.actions" @action="onAction"
    class="w-full m-5">
    <Panel>
      <Form id="create-product" @submit.prevent="onSubmit">
        <div class="flex">
          <div class="w-1/2 p-2">
            <TextInput class="mb-4" type="text" :required="true" name="name" v-model="form.name"
              :label="trans('product.labels.name')" />
          </div>
          <div class="w-1/2 p-2">
            <label :for="name" class="text-sm text-gray-500">
              Categoría
              <span class="text-red-600">*</span>
            </label>
            <select
              class="mb-4 block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-theme-500 focus:border-theme-500 text-sm"
              :required="true" name="cate_id" v-model="form.cate_id" :label="trans('product.labels.category')">
              <option :value="null">Seleccione una categoría</option>
              <option v-for="item in page.categories" :value="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="flex">
          <div class="w-1/3 p-2">
            <TextInput class="mb-4" type="text" :required="true" name="slug" v-model="form.slug"
              :label="trans('product.labels.slug')" />
          </div>
          <div class="w-1/3 p-2">
            <TextInput class="mb-4" type="text" :required="true" name="sku" v-model="form.sku"
              :label="trans('product.labels.sku')" />
          </div>
          <div class="w-1/3 p-2">
            <TextInput class="mb-4" type="text" :required="true" name="tax" v-model="form.tax"
              :label="trans('product.labels.tax')" />
          </div>
        </div>

        <div class="flex">
          <div class="w-full p-2">
            <TextInput class="mb-4" type="textarea" :required="true" name="small_description"
              v-model="form.small_description" :label="trans('product.labels.small_description')" />
          </div>
        </div>

        <!-- <div class="flex">
          <div class="w-full p-2">
            <TextInput class="mb-4" type="textarea" :required="true" name="description" v-model="form.description"
              :label="trans('product.labels.description')" />
          </div>
        </div> -->

        <div class="flex">
          <div class="w-full p-2">
            <div class="mb-4">
              <label for="description">{{ trans('product.labels.description') }}</label>
              <div id="quill-editor" name="description" style="height: 300px;"></div>
            </div>
          </div>
        </div>

        <div class="flex">
          <div class="w-1/4 p-2">
            <TextInput class="mb-4" type="text" :required="true" name="original_price" v-model="form.original_price"
              :label="trans('product.labels.original_price')" />
          </div>
          <div class="w-1/4 p-2">
            <TextInput class="mb-4" type="text" :required="true" name="selling_price" v-model="form.selling_price"
              :label="trans('product.labels.selling_price')" />
          </div>
          <div class="w-1/4 p-2">
            <TextInput class="mb-4" type="number" :required="true" name="min_qty_mayor" v-model="form.min_qty_mayor"
              label="Cantidad mínima" />
          </div>
          <div class="w-1/4 p-2">
            <TextInput class="mb-4" type="number" :required="true" name="min_qty_embalaje"
              v-model="form.min_qty_embalaje" label="Cantidad mínima(Embalaje)" />
          </div>
          <div class="w-1/4 p-2">
            <TextInput class="mb-4" type="text" :required="true" name="qty" v-model="form.qty"
              :label="trans('product.labels.qty')" />
          </div>
          <div class="w-1/4 p-2">
            <label :for="name" class="text-sm text-gray-500">
              Estado
              <span class="text-red-600">*</span>
            </label>
            <select
              class="mb-4 block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-theme-500 focus:border-theme-500 text-sm"
              :required="true" name="status" v-model="form.status" :label="trans('product.labels.status')">
              <option value="1">Activo</option>
              <option value="0">Desactivado</option>
            </select>
          </div>
        </div>

        <div class="w-full p-2">
          <label for="">Imágen Principal</label>
          <br />
          <br />
          <a @click="$refs.btnAvatar.click()"
            class="my-4 px-4 py-2 border border-transparent text-sm rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 text-center transition text-white bg-theme-600 hover:bg-theme-800 focus:bg-theme-800 focus:ring-theme-800">
            Agregar Imágen Principal
            <i class="fa fa-image"></i>
          </a>
          <br />
          <div v-if="form.image && !form.imageSrc" style="border: 1px solid #f1f1f1; margin-right: 5px;"
            class="w-1/6 p-5 mt-3">
            <img class="img-tumbail" :src="form.image" alt="" />
          </div>
          <div v-if="form.imageSrc" style="border: 1px solid #f1f1f1; margin-right: 5px;" class="w-1/6 p-5 mt-3">
            <img class="img-tumbail" :src="form.imageSrc" alt="" />
          </div>
          <input ref="btnAvatar" type="file" style="display: none;" accept="image/*" @change="onFileChange" />
        </div>
        <br />
        <hr />
        <div class="w-full p-2">
          <label for="">Imágenes Adicionales</label>
          <br />
          <br />
          <a @click="$refs.btnImages.click()"
            class="px-4 py-2 border border-transparent text-sm rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 text-center transition text-white bg-theme-600 hover:bg-theme-800 focus:bg-theme-800 focus:ring-theme-800">
            Agregar Imágenes
            <i class="fa fa-image"></i>
          </a>
          <input type="file" style="display: none;" multiple="true" ref="btnImages" accept="image/*"
            @change="onFileChangeMultiple" />
          <div class="flex">
            <div style="
                position: relative;
                border: 1px solid #f1f1f1;
                margin-right: 5px;
              " v-for="item in form.imagesSrc" class="w-1/6 p-5 mt-3">
              <img class="img-tumbail" :src="item.imageSrc" alt="" />
              <a @click="deleteImage(item)" style="position: absolute; bottom: 0; right: 0;"
                class="uppercase cursor-pointer text-lg mr-3 text-danger-400" title="Delete">
                <i class="fa fa-trash"></i>
              </a>
            </div>
          </div>
        </div>
        
        <!-- <Panel>
          <h3>Variaciones</h3>
          <br />
          <div class="flex">
            <div class="w-1/3 p-2">
              <label :for="variation" class="text-sm text-gray-500">
                Variación
                <span class="text-red-600">*</span>
              </label>
              <select
                class="mb-4 block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-theme-500 focus:border-theme-500 text-sm"
                :required="true" name="variation_id" v-model="formVariation.variation"
                :label="trans('product.labels.variation')">
                <option :value="null">Seleccione una variacón</option>
                <option v-for="item in page.variations" :value="item">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="w-1/2 p-2">
              <TextInput class="mb-4" type="text" :required="true" name="variation_description"
                v-model="formVariation.variation_description" :label="trans('product.labels.variation_description')" />
            </div>
            <div class="w-1/3 p-2">
              <TextInput class="mb-4" type="text" :required="true" name="variation_price" v-model="formVariation.price"
                :label="trans('product.labels.variation_price')" />
            </div>
            <div class="w-1/3 p-2">
              <br />
              <a @click="addVariation()"
                class="px-4 py-2 border border-transparent text-sm rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 text-center transition text-white bg-theme-600 hover:bg-theme-800 focus:bg-theme-800 focus:ring-theme-800">
                <i class="mr-1 fa fa-plus"></i>
                Agregar
              </a>
            </div>
          </div>
          <div>
            <div class="w-full shadow border-b border-gray-200 mb-8 sm:rounded-lg">
              <table class="w-full divide-y divide-gray-200 table-auto">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col"
                      class="align-middle px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <div class="leading-loose inline-block">Variación</div>
                    </th>
                    <th scope="col"
                      class="align-middle px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <div class="leading-loose inline-block">Description</div>
                    </th>
                    <th scope="col"
                      class="align-middle px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <div class="leading-loose inline-block">Precio</div>
                    </th>
                    <th scope="col"
                      class="align-middle px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Acciones
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="record in page.recordsVariations">
                    <td class="px-6 py-4 whitespace-nowrap text-sm">
                      {{ record.variation.variation_name }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm">
                      {{ record.variation.variation_description }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm">
                      {{ record.price }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <a @click="deleteRecord(record)" class="uppercase cursor-pointer text-lg mr-3 text-danger-400"
                        title="Delete">
                        <i class="fa fa-trash"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Panel> -->

      </Form>
    </Panel>
  </Page>
</template>

<script>
import { defineComponent, reactive, onMounted, onBeforeMount } from 'vue'
import { trans } from '@/helpers/i18n'
import { useAuthStore } from '@/stores/auth'
import Button from '@/views/components/input/Button'
import TextInput from '@/views/components/input/TextInput'
import Dropdown from '@/views/components/input/Dropdown'
import Alert from '@/views/components/Alert'
import Panel from '@/views/components/Panel'
import Page from '@/views/layouts/Page'
import FileInput from '@/views/components/input/FileInput'
import ProductService from '@/services/ProductService'
import CategoryService from '@/services/CategoryService'
import { clearObject, reduceProperties, fillObject } from '@/helpers/data'
import { toUrl } from '@/helpers/routing'
import Form from '@/views/components/Form'
import { useAlertStore } from '@/stores'
import { getResponseError } from '@/helpers/api'
import { useRoute } from 'vue-router'

import Quill from 'quill';
import 'quill/dist/quill.snow.css';

export default defineComponent({
  components: {
    Form,
    FileInput,
    Panel,
    Alert,
    Dropdown,
    TextInput,
    Button,
    Page,
  },
  setup() {
    const { user } = useAuthStore()
    const route = useRoute()
    const form = reactive({
      name: '',
      slug: '',
      small_description: '',
      description: '',
      cate_id: null,
      image: '',
      images: [],
      imagesSrc: [],
      variations: [],
      status: '1',
      sku: '',
      qty: '',
      original_price: '',
      selling_price: '',
      tax: '',
      min_qty_embalaje: 0,
      min_qty_mayor: 0,
    })

    const formVariation = reactive({
      variation: null,
      price: '',
    })

    const page = reactive({
      id: 'create_product',
      title: trans('global.pages.product_create'),
      filters: false,
      breadcrumbs: [
        {
          name: trans('global.pages.products'),
          to: toUrl('/product/list'),
        },
        {
          name: trans('global.pages.product_create'),
          active: true,
        },
      ],
      actions: [
        {
          id: 'back',
          name: trans('global.buttons.back'),
          icon: 'fa fa-angle-left',
          to: toUrl('/product/list'),
          theme: 'outline',
        },
        {
          id: 'submit',
          name: trans('global.buttons.save'),
          icon: 'fa fa-save',
          type: 'submit',
        },
      ],
      variations: [],
      recordsVariations: [],
      categories: [],
    })

    const service = new ProductService()
    const categoryService = new CategoryService()

    function deleteImage(item) {
      let idx = this.form.imagesSrc.indexOf(item)
      this.form.imagesSrc.splice(idx, 1)
      this.form.images.splice(idx, 1)
    }

    function getCategories() {
      categoryService
        .index()
        .then((response) => {
          page.categories = response.data.data
        })
        .catch((error) => {
          alertStore.error(getResponseError(error))
          table.loading = false
        })
    }

    function getVariations() {
      service
        .getVariations()
        .then((response) => {
          page.variations = response.data.data
        })
        .catch((error) => {
          console.log('getResponseError(error)', getResponseError(error))
          alertStore.error(getResponseError(error))
          table.loading = false
        })
    }

    function addVariation() {
      let row = {
        variation: {
          variation_id: this.formVariation.variation.id,
          variation_name: this.formVariation.variation.name,
          variation_description: this.formVariation.variation_description,
        },
        price: this.formVariation.price,
      }
      this.page.recordsVariations.push(row)
    }

    function deleteRecord(item) {
      let idx = this.page.recordsVariations.indexOf(item)
      this.page.recordsVariations.splice(idx, 1)
    }
    function onFileChange(e) {
      const file = e.target.files[0]
      let url = URL.createObjectURL(file)
        ; (form.image = file), (form.imageSrc = url)
    }
    function onFileChangeMultiple(e) {
      console.log('dfsds')
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i]
        let url = URL.createObjectURL(file)
        let item = { image: file, imageSrc: url }
        form.images.push(file)
        form.imagesSrc.push({ imageSrc: url })
      }
    }

    function onAction(data) {
      switch (data.action.id) {
        case 'submit':
          onSubmit()
          break
      }
    }

    onBeforeMount(() => {
      service.edit(route.params.id).then((response) => {
        fillObject(form, response.data.model)
        page.loading = false

        //alert(response.data.model.description)

        quillEditor.root.innerHTML = response.data.model.description;
      })
    })

    function onSubmit() {
      form.variations = page.recordsVariations
      service
        .handleUpdate(
          'edit-product',
          route.params.id,
          reduceProperties(form, 'id'),
        )
        .then(() => {
          //clearObject(form)
        })
      return false
    }
    const statusList = [
      { id: 0, title: 'Active' },
      { id: 1, title: 'Inactive' },
    ]

    let quillEditor = null;

    onMounted(() => {

      // Inicializar Quill editor
      quillEditor = new Quill('#quill-editor', {
        theme: 'snow',
        placeholder: 'Escribe la descripción del producto...',
        modules: {
          toolbar: [
            // [{ header: [1, 2, false] }],
            [{ 'font': [] }], // Agregar selección de fuente
            [{ 'size': ['small', false, 'large', 'huge'] }], // Agregar selección de tamaño de fuente          
            ['bold', 'italic', 'underline'],
            [{ 'align': [] }],                    // Alineación (izquierda, centro, derecha, justificado)
            [{ 'color': [] }, { 'background': [] }] // 
            // ['image', 'code-block']
          ]
        }
      });

      // Configurar para que el valor de Quill se actualice en el modelo
      quillEditor.on('text-change', () => {
        form.description = quillEditor.root.innerHTML;
      });


      getVariations(), getCategories()
    })

    return {
      trans,
      user,
      form,
      page,
      onSubmit,
      onAction,
      statusList,
      addVariation,
      deleteRecord,
      formVariation,
      getVariations,
      onFileChange,
      deleteImage,
      onFileChangeMultiple,
    }
  },
})
</script>

<style scoped></style>

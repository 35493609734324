<template>
  <Page :title="page.title" :breadcrumbs="page.breadcrumbs" :actions="page.actions" @action="onPageAction"
    class="w-full m-5">

    <template #filters v-if="page.toggleFilters">
      
      <Filters @clear="onFiltersClear">
        <FiltersRow class="grid grid-cols-6 gap-4">
          <!-- Cliente - Ocupa la mitad (3 columnas) -->
          <FiltersCol class="col-span-2">
            <div>
              <label :for="name" class="text-sm text-gray-500">
                Cliente
                <!-- <span class="text-red-600">*</span> -->
              </label>
              <model-select :options="page.customers" v-model="page.customer_id"
                placeholder="Seleccione un cliente"></model-select>
            </div>
          </FiltersCol>
          <!-- Fecha de Inicio - Ocupa 1/6 (1 columna) -->
          <FiltersCol class="col-span-1">
            <div>
              <label :for="name" class="text-sm text-gray-500">
                Fecha de Inicio
              </label>
              <input v-model="page.start_date"
                class="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-theme-500 focus:border-theme-500 text-sm"
                type="date">
            </div>
          </FiltersCol>
          <!-- Fecha de Fin - Ocupa 1/6 (1 columna) -->
          <FiltersCol class="col-span-1">
            <div>
              <label :for="name" class="text-sm text-gray-500">
                Fecha de Fin
              </label>
              <input v-model="page.end_date"
                class="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-theme-500 focus:border-theme-500 text-sm"
                type="date">
            </div>
          </FiltersCol>
          <!-- Botón Filtrar - Ocupa 1/6 (1 columna) -->
          <FiltersCol class="col-span-1">
            <button @click="fetchPage(null)"
              class="btn-filter border border-transparent text-sm rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 text-center transition text-white bg-theme-600 hover:bg-theme-800 focus:bg-theme-800 focus:ring-theme-800 w-full"
              title="Filtrar"><i class="mr-1 fa fa-filter"></i> Filtrar</button>
          </FiltersCol>
          <FiltersCol class="col-span-1">
            <button @click="exportToExcel"
              class="btn-excel border border-transparent text-sm rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 text-center transition text-white bg-theme-600 hover:bg-green-800 focus:bg-green-800">
              <i class="mr-1 fa fa-file-excel-o"></i> Exportar a Excel
            </button>
          </FiltersCol>
        </FiltersRow>
      </Filters>

    </template>

    <template #default>
      <Table :id="page.id" v-if="table" :headers="table.headers" :sorting="table.sorting" :records="table.records"
        :pagination="table.pagination" :is-loading="table.loading" @page-changed="onTablePageChange"
        @sort="onTableSort">
        <template v-slot:content-status="props">
          <span v-if="props.item.status"
            class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
            v-html="trans('category.status.active')"></span>
          <span v-else class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
            v-html="trans('category.status.not_active')"></span>
        </template>
      </Table>
    </template>
  </Page>
</template>

<script>
import { trans } from '@/helpers/i18n'
import ReporteService from '@/services/ReporteService'
import CustomerService from '@/services/CustomerService'
import { watch, onMounted, defineComponent, reactive, ref } from 'vue'
import { getResponseError, prepareQuery } from '@/helpers/api'
import { toUrl } from '@/helpers/routing'
import { useAlertStore } from '@/stores'
import alertHelpers from '@/helpers/alert'
import Page from '@/views/layouts/Page'
import Table from '@/views/components/Table'
import Avatar from '@/views/components/icons/Avatar'
import Filters from '@/views/components/filters/Filters'
import FiltersRow from '@/views/components/filters/FiltersRow'
import FiltersCol from '@/views/components/filters/FiltersCol'
import TextInput from '@/views/components/input/TextInput'
import Dropdown from '@/views/components/input/Dropdown'
import { ModelSelect } from 'vue-search-select'
export default defineComponent({
  components: {
    Dropdown,
    TextInput,
    FiltersCol,
    FiltersRow,
    Filters,
    Page,
    Table,
    Avatar,
    ModelSelect
  },
  setup() {
    const service = new ReporteService()
    const customerService = new CustomerService()
    const alertStore = useAlertStore()
    const mainQuery = reactive({
      page: 1,
      search: '',
      sort: '',
      customer_id: null,
      page: [],
      filters: {
        start_date: {
          value: null,
          comparison: '>',
        },
        end_date: {
          value: null,
          comparison: '<',
        },
      },
    })

    const page = reactive({
      id: 'list_users',
      title: 'Reporte de ventas por cliente',
      customers: [],
      breadcrumbs: [
        {
          name: 'Reporte de Ventas por cliente',
          to: toUrl('/category'),
          active: true,
        },
      ],
      toggleFilters: true,
      start_date: null,
      end_date: null
    })

    const table = reactive({
      headers: {
        order_date: 'Fecha',
        customer_id: 'Número de Documento',
        name: 'Cliente',
        customer_email: 'Correo',
        total: 'Total'
      },
      sorting: {
        first_name: true,
        last_name: true,
      },
      pagination: {
        meta: null,
        links: null,
      },
      actions: null,
      loading: false,
      records: null,
    })

    function exportToExcel() {
      const queryString = filter();  // Obtén los filtros aplicados

      const url = `../../api/reporte-ventas-cliente/export${queryString}`;
      window.open(url, '_blank');  // Abre la URL en una nueva ventana o pestaña
    }


    function onTableSort(params) {
      mainQuery.sort = params
    }

    function onTablePageChange(page) {
      mainQuery.page = page
    }
    function filter() {
      let queryString = "?";
      if (page.start_date) {
        queryString += "start_date=" + page.start_date;
      } if (page.end_date) {
        queryString += "&end_date=" + page.end_date;
      } if (page.customer_id) {
        queryString += "&customer_id=" + page.customer_id;
      }
      console.log("filter", queryString)
      return queryString;
    }

    function onTableAction(params) {
      switch (params.action.id) {
        case 'delete':
          alertHelpers.confirmDanger(function () {
            service.delete(params.item.id).then(function (response) {
              fetchPage(mainQuery)
            })
          })
          break
      }
    }

    function onPageAction(params) {
      switch (params.action.id) {
        case 'filters':
          page.toggleFilters = !page.toggleFilters
          break
      }
    }

    function onFiltersClear() {
      let clonedFilters = mainQuery.filters
      for (let key in clonedFilters) {
        clonedFilters[key].value = ''
      }
      mainQuery.filters = clonedFilters
    }

    function getCustomers() {
      customerService.
        getList()
        .then((response) => {
          page.customers = response.data.data
        })
        .catch((error) => {
          alertStore.error(getResponseError(error))
          table.loading = false
        })
    }

    function fetchPage(params) {

      table.records = []
      table.loading = true
      let query = filter();
      service
        .ventasCliente(query)
        .then((response) => {
          table.records = response.data.data
          table.pagination.meta = response.data.meta
          table.pagination.links = response.data.links
          table.loading = false;
        })
        .catch((error) => {
          alertStore.error(getResponseError(error))
          table.loading = false
        })
    }
    watch(mainQuery, (newTableState) => {
      fetchPage(mainQuery)
    })

    onMounted(() => {
      fetchPage(mainQuery);
      getCustomers();
    })

    return {
      trans,
      page,
      table,
      onTablePageChange,
      onTableAction,
      onTableSort,
      onPageAction,
      onFiltersClear,
      mainQuery,
      filter,
      fetchPage,
      getCustomers,
      exportToExcel
    }
  },
})
</script>

<style>
.btn-excel {
  margin-top: 20px !important;
  padding: 10px;
  width: 200px;
  background-color: green;
}
</style>
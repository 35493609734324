<template>
    <Page :title="page.title" :breadcrumbs="page.breadcrumbs" :actions="page.actions" @action="onPageAction"
        class="w-full m-5">

        <template #filters v-if="page.toggleFilters">
            <Filters @clear="onFiltersClear">
                <FiltersRow>
                    <FiltersCol>
                        <TextInput name="name" :label="trans('coupon.labels.name')"
                            v-model="mainQuery.filters.name.value"></TextInput>
                    </FiltersCol>
                    <!-- <FiltersCol>
                        <TextInput name="status" :label="trans('category.labels.status')"
                            v-model="mainQuery.filters.status.value"></TextInput>
                    </FiltersCol> -->
                    <FiltersCol>
                        <div>
                            <label :for="name" class="text-sm text-gray-500">
                                Estado
                            </label>
                            <select
                                class="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-theme-500 focus:border-theme-500 text-sm"
                                :required="true" name="status" v-model="mainQuery.filters.status.value"
                                :label="trans('coupon.labels.status')">
                                <option value="">Seleccione el Estado</option>
                                <option value="1">Activo</option>
                                <option value="0">Desactivado</option>
                            </select>
                        </div>
                    </FiltersCol>
                </FiltersRow>
            </Filters>
        </template>

        <template #default>
            <Table :id="page.id" v-if="table" :headers="table.headers" :sorting="table.sorting" :actions="table.actions"
                :records="table.records" :pagination="table.pagination" :is-loading="table.loading"
                @page-changed="onTablePageChange" @action="onTableAction" @sort="onTableSort">
                <template v-slot:content-id="props">
                    <div class="flex items-center">
                        <div class="ml-4">
                            <div class="text-sm font-medium text-gray-900">
                                {{ props.item.full_name }}
                            </div>
                            <div class="text-sm text-gray-500">
                                {{ trans('users.labels.id') + ': ' + props.item.id }}
                            </div>
                        </div>
                    </div>
                </template>

                <!-- Moved the is_proveedor template to a new slot -->
                <template v-slot:content-is_proveedor="props">
                    <span v-if="props.item.is_proveedor"
                        class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                        v-html="trans('coupon.status.proveedor')"></span>
                    <span v-else
                        class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
                        v-html="trans('coupon.status.not_proveedor')"></span>
                </template>

                <template v-slot:content-status="props">
                    <span v-if="props.item.status"
                        class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                        v-html="trans('coupon.status.active')"></span>
                    <span v-else
                        class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
                        v-html="trans('coupon.status.not_active')"></span>                    
                </template>                

                <template v-slot:content-role="props">
                    {{
                        props.item.roles.map((entry) => {
                            return entry.title
                        }).join(', ')
                    }}
                </template>
            </Table>
        </template>
    </Page>
</template>

<script>

import { trans } from "@/helpers/i18n";
import CouponService from "@/services/CouponService";
import { watch, onMounted, defineComponent, reactive, ref } from 'vue';
import { getResponseError, prepareQuery } from "@/helpers/api";
import { toUrl } from "@/helpers/routing";
import { useAlertStore } from "@/stores";
// import alertHelpers from "@/helpers/alert";
import Swal from 'sweetalert2';
import Page from "@/views/layouts/Page";
import Table from "@/views/components/Table";
import Avatar from "@/views/components/icons/Avatar";
import Filters from "@/views/components/filters/Filters";
import FiltersRow from "@/views/components/filters/FiltersRow";
import FiltersCol from "@/views/components/filters/FiltersCol";
import TextInput from "@/views/components/input/TextInput";
import Dropdown from "@/views/components/input/Dropdown";

export default defineComponent({
    components: {
        Dropdown,
        TextInput,
        FiltersCol,
        FiltersRow,
        Filters,
        Page,
        Table,
        Avatar
    },
    setup() {
        const service = new CouponService();
        const alertStore = useAlertStore();
        const mainQuery = reactive({
            page: 1,
            search: '',
            sort: '',
            filters: {
                name: {
                    value: '',
                    comparison: '='
                },
                status: {
                    value: '',
                    comparison: '='
                }
            }
        });

        const page = reactive({
            id: 'list_users',
            title: trans('global.pages.coupon'),
            breadcrumbs: [
                {
                    name: trans('global.pages.coupon'),
                    to: toUrl('/coupon'),
                    active: true,
                }
            ],
            actions: [
                {
                    id: 'filters',
                    name: trans('global.buttons.filters'),
                    icon: "fa fa-filter",
                    theme: 'outline',
                },
                {
                    id: 'new',
                    name: trans('global.buttons.add_new'),
                    icon: "fa fa-plus",
                    to: toUrl('/coupon/create')
                }
            ],
            toggleFilters: true,
        });

        const table = reactive({
            headers: {
                id: trans('coupon.labels.id'),
                name: trans('coupon.labels.name'),
                description: trans('coupon.labels.description'),
                discount: trans('coupon.labels.discount'),
                start_date: trans('coupon.labels.start_date'),
                end_date: trans('coupon.labels.end_date'),
                status: trans('coupon.labels.status'),
                is_proveedor: trans('coupon.labels.is_proveedor'),
            },
            sorting: {
                first_name: true,
                last_name: true
            },
            pagination: {
                meta: null,
                links: null,
            },
            actions: {
                edit: {
                    id: 'edit',
                    name: trans('global.actions.edit'),
                    icon: "fa fa-edit",
                    showName: false,
                    to: toUrl('/coupon/{id}/edit')
                },
                delete: {
                    id: 'delete',
                    name: trans('global.actions.delete'),
                    icon: "fa fa-trash",
                    showName: false,
                    danger: true,
                }
            },
            loading: false,
            records: null
        })

        function onTableSort(params) {
            mainQuery.sort = params;
        }

        function onTablePageChange(page) {
            mainQuery.page = page;
        }

        // function onTableAction(params) {
        //     switch (params.action.id) {
        //         case 'delete':
        //             alertHelpers.confirmDanger(function () {
        //                 service.delete(params.item.id).then(function (response) {
        //                     fetchPage(mainQuery);
        //                 });
        //             })
        //             break;
        //     }
        // }

        function onTableAction(params) {
            switch (params.action.id) {
                case 'delete':
                    Swal.fire({
                        title: '¿Estás seguro de eliminar el cupón?',
                        text: "¡No podrás revertir esta acción!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Sí, elimínalo!',
                        cancelButtonText: 'Cancelar'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            service.delete(params.item.id).then(function (response) {
                                fetchPage(mainQuery);
                                Swal.fire(
                                    'Eliminado!',
                                    'El registro ha sido eliminado.',
                                    'success'
                                );
                            }).catch((error) => {
                                Swal.fire(
                                    'Error!',
                                    'No se pudo eliminar el registro.',
                                    'error'
                                );
                            });
                        }
                    });
                    break;
            }
        }


        function onPageAction(params) {
            switch (params.action.id) {
                case 'filters':
                    page.toggleFilters = !page.toggleFilters;
                    break;
            }
        }

        function onFiltersClear() {
            let clonedFilters = mainQuery.filters;
            for (let key in clonedFilters) {
                clonedFilters[key].value = '';
            }
            mainQuery.filters = clonedFilters;
        }

        function fetchPage(params) {
            table.records = [];
            table.loading = true;
            let query = prepareQuery(params);
            service
                .index(query)
                .then((response) => {
                    table.records = response.data.data;
                    table.pagination.meta = response.data.meta;
                    table.pagination.links = response.data.links;
                    table.loading = false;
                })
                .catch((error) => {
                    alertStore.error(getResponseError(error));
                    table.loading = false;
                });
        }

        watch(mainQuery, (newTableState) => {
            fetchPage(mainQuery);
        });

        onMounted(() => {
            fetchPage(mainQuery);
        });

        return {
            trans,
            page,
            table,
            onTablePageChange,
            onTableAction,
            onTableSort,
            onPageAction,
            onFiltersClear,
            mainQuery
        }

    },
});
</script>

<template>
  <Page
    :title="page.title"
    :breadcrumbs="page.breadcrumbs"
    :actions="page.actions"
    @action="onAction"
    class="w-full m-5"
  >
    <Panel>
      <Form id="create-category" @submit.prevent="onSubmit">
        <TextInput
          class="mb-4"
          type="text"
          :required="true"
          name="name"
          v-model="form.name"
          :label="trans('category.labels.name')"
        />
        <!-- <TextInput
          class="mb-4"
          type="text"
          :required="true"
          name="slug"
          v-model="form.slug"
          :label="trans('category.labels.slug')"
        />
        <TextInput
          class="mb-4"
          type="text"
          :required="true"
          name="description"
          v-model="form.description"
          :label="trans('category.labels.description')"
        /> -->
        <label :for="name" class="text-sm text-gray-500">
          Estado
          <span class="text-red-600">*</span>
        </label>
        <select
          class="mb-4 block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-theme-500 focus:border-theme-500 text-sm"
          :required="true"
          name="status"
          v-model="form.status"
          :label="trans('product.labels.status')"
        >
          <option value="1">Activo</option>
          <option value="0">Desactivado</option>
        </select>
        <label :for="name" class="text-sm text-gray-500">
          Principal
          <input type="checkbox" v-model="form.popular">   
          <br>
          <br>
        </label>
        <label for="">Imágen </label>
          <br />
          <br />
          <a @click="$refs.btnAvatar.click()" class="my-4 px-4 py-2 border border-transparent text-sm rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 text-center transition text-white bg-theme-600 hover:bg-theme-800 focus:bg-theme-800 focus:ring-theme-800">
            Agregar Imágen  <i class="fa fa-image"></i></a>
            <br>
          <div v-if="form.imageSrc" style="border:1px solid #f1f1f1; margin-right:5px" class="w-1/6 p-5 mt-3">
            <img   class="img-tumbail"  :src="form.imageSrc" alt="" />
          </div>
          <input ref="btnAvatar" type="file"  style="display: none" accept="image/*" @change="onFileChange" />
      </Form>
    </Panel>
  </Page>
</template>

<script>
import { defineComponent, reactive, onBeforeMount } from 'vue'
import { trans } from '@/helpers/i18n'
import { useAuthStore } from '@/stores/auth'
import { useRoute } from 'vue-router'
import Button from '@/views/components/input/Button'
import TextInput from '@/views/components/input/TextInput'
import Dropdown from '@/views/components/input/Dropdown'
import Alert from '@/views/components/Alert'
import Panel from '@/views/components/Panel'
import Page from '@/views/layouts/Page'
import FileInput from '@/views/components/input/FileInput'
import CategoryService from '@/services/CategoryService'
import { clearObject, fillObject, reduceProperties } from '@/helpers/data'
import { toUrl } from '@/helpers/routing'
import Form from '@/views/components/Form'

export default defineComponent({
  components: {
    Form,
    FileInput,
    Panel,
    Alert,
    Dropdown,
    TextInput,
    Button,
    Page,
  },
  setup() {
    const { user } = useAuthStore()
    const route = useRoute()
    const form = reactive({
      name: '',
      slug: '',
      description: '',
      imageSrc:null,
      image: '',
      status: '',
      popular:false
    })

    const page = reactive({
      id: 'edit_users',
      title: trans('global.pages.category_edit'),
      filters: false,
      breadcrumbs: [
        {
          name: trans('global.pages.categories'),
          to: toUrl('/category/list'),
        },
        {
          name: trans('global.pages.category_edit'),
          active: true,
        },
      ],
      actions: [
        {
          id: 'back',
          name: trans('global.buttons.back'),
          icon: 'fa fa-angle-left',
          to: toUrl('/category/list'),
          theme: 'outline',
        },
        {
          id: 'submit',
          name: trans('global.buttons.save'),
          icon: 'fa fa-save',
          type: 'submit',
        },
      ],
    })

    const service = new CategoryService()

    function onAction(data) {
      switch (data.action.id) {
        case 'submit':
          onSubmit()
          break
      }
    }

    onBeforeMount(() => {
      service.edit(route.params.id).then((response) => {
        if(response.data.model.popular == 1){
          response.data.model.popular = true;
        }
        fillObject(form, response.data.model)
        console.log("form",form)
        page.loading = false
      })
    })

    function onSubmit() {
      service.handleUpdate(
        'edit-category',
        route.params.id,
        reduceProperties(form, 'roles', 'id'),
      )
      return false
    }
    function onFileChange(e) {
      const file = e.target.files[0]
      let url = URL.createObjectURL(file)
      ;(form.image = file), (form.imageSrc = url)
    }
    const roles = [{ id: 1, name: 2 }]

    return {
      trans,
      user,
      form,
      page,
      onFileChange,
      onSubmit,
      onAction,
      roles,
    }
  },
})
</script>

<style scoped></style>

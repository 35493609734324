<template>
  <Page
    :title="page.title"
    :breadcrumbs="page.breadcrumbs"
    :actions="page.actions"
    @action="onPageAction"
    class="w-full m-5"
  >
    <template #filters v-if="page.toggleFilters">
      <Filters @clear="onFiltersClear">
        <FiltersRow>
          <FiltersCol>
            <TextInput
              name="name"
              :label="trans('products.labels.name')"
              v-model="mainQuery.filters.name.value"
            ></TextInput>
          </FiltersCol>
          <FiltersCol>
            <TextInput
              name="sku"
              :label="trans('products.labels.sku')"
              v-model="mainQuery.filters.sku.value"
            ></TextInput>
          </FiltersCol>
          <FiltersCol>
            <div>
            <label :for="name" class="text-sm text-gray-500">
              Categoría
            </label>
            <select
              class="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-theme-500 focus:border-theme-500 text-sm"
              :required="true"
              name="cate_id"
              v-model="mainQuery.filters.cate_id.value"
              :label="trans('product.labels.category')"
            >
              <option :value="null">Seleccione una categoría</option>
              <option v-for="item in page.categories" :value="item.id">
                {{ item.name }}
              </option>
            </select>
        </div>
          </FiltersCol>
          <FiltersCol>
            <TextInput
              name="status"
              :label="trans('products.labels.status')"
              v-model="mainQuery.filters.status.value"
            ></TextInput>
          </FiltersCol>
        </FiltersRow>
      </Filters>
    </template>

    <template #default>
      <Table
        :id="page.id"
        v-if="table"
        :headers="table.headers"
        :sorting="table.sorting"
        :actions="table.actions"
        :records="table.records"
        :pagination="table.pagination"
        :is-loading="table.loading"
        @page-changed="onTablePageChange"
        @action="onTableAction"
        @sort="onTableSort"
      >
        <template v-slot:content-id="props">
          <div class="flex items-center">
            <!-- <div class="flex-shrink-0 h-10 w-10">
                            <img v-if="props.item.avatar_url" :src="props.item.avatar_url" class="h-10 w-10 rounded-full" alt=""/>
                            <Avatar v-else class="w-10 h-10 text-gray-400 rounded-full"/>
                        </div> -->
            <div class="ml-4">
              <div class="text-sm font-medium text-gray-900">
                {{ props.item.full_name }}
              </div>
              <div class="text-sm text-gray-500">
                {{ trans('users.labels.id') + ': ' + props.item.id }}
              </div>
            </div>
          </div>
        </template>
        <template v-slot:content-status="props">
          <span
            v-if="props.item.status"
            class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
            v-html="trans('product.status.active')"
          ></span>
          <span
            v-else
            class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
            v-html="trans('product.status.not_active')"
          ></span>
        </template>
        <template v-slot:content-role="props">
          {{
            props.item.roles
              .map((entry) => {
                return entry.title
              })
              .join(', ')
          }}
        </template>
      </Table>
    </template>
  </Page>
</template>

<script>
import { trans } from '@/helpers/i18n'
import ProductService from '@/services/ProductService'
import CategoryService from '@/services/CategoryService'
import { watch, onMounted, defineComponent, reactive, ref } from 'vue'
import { getResponseError, prepareQuery } from '@/helpers/api'
import { toUrl } from '@/helpers/routing'
import { useAlertStore } from '@/stores'
import alertHelpers from '@/helpers/alert'
import Page from '@/views/layouts/Page'
import Table from '@/views/components/Table'
import Avatar from '@/views/components/icons/Avatar'
import Filters from '@/views/components/filters/Filters'
import FiltersRow from '@/views/components/filters/FiltersRow'
import FiltersCol from '@/views/components/filters/FiltersCol'
import TextInput from '@/views/components/input/TextInput'
import Dropdown from '@/views/components/input/Dropdown'

export default defineComponent({
  components: {
    Dropdown,
    TextInput,
    FiltersCol,
    FiltersRow,
    Filters,
    Page,
    Table,
    Avatar,
  },
  setup() {
    const service = new ProductService()
    const categoryService = new CategoryService()
    const alertStore = useAlertStore()
    const mainQuery = reactive({
      page: 1,
      search: '',
      sort: '',
      filters: {
        name: {
          value: '',
          comparison: '=',
        },
        sku: {
          value: '',
          comparison: '=',
        },
        cate_id: {
          value: '',
          comparison: '=',
        },
        status: {
          value: '',
          comparison: '=',
        },
      },
    })

    const page = reactive({
      id: 'list_users',
      title: 'Existencias',
      breadcrumbs: [
        {
          name: 'Existencias',
          to: toUrl('/product'),
          active: true,
        },
      ],
      actions: [
        {
          id: 'filters',
          name: trans('global.buttons.filters'),
          icon: 'fa fa-filter',
          theme: 'outline',
        },
      ],
      toggleFilters: true,
      categories:[]
    })

    const table = reactive({
      headers: {
        id: trans('product.labels.id'),
        sku: trans('product.labels.sku'),
        qty: trans('product.labels.qty'),
        name: trans('product.labels.name'),
        status: trans('product.labels.status'),
      },
      sorting: {
        first_name: true,
        last_name: true,
      },
      pagination: {
        meta: null,
        links: null,
      },
      actions: {
        edit: {
          id: 'edit',
          name: trans('global.actions.edit'),
          icon: 'fa fa-list',
          showName: false,
          to: toUrl('/stock/{id}/detail'),
        },
      },
      loading: false,
      records: null,
    })

    function onTableSort(params) {
      mainQuery.sort = params
    }

    function onTablePageChange(page) {
      mainQuery.page = page
    }

    function onTableAction(params) {
      switch (params.action.id) {
        case 'delete':
          alertHelpers.confirmDanger(function () {
            service.delete(params.item.id).then(function (response) {
              fetchPage(mainQuery)
            })
          })
          break
      }
    }

    function onPageAction(params) {
      switch (params.action.id) {
        case 'filters':
          page.toggleFilters = !page.toggleFilters
          break
      }
    }

    function onFiltersClear() {
      let clonedFilters = mainQuery.filters
      for (let key in clonedFilters) {
        clonedFilters[key].value = ''
      }
      mainQuery.filters = clonedFilters
    }

    function fetchPage(params) {
      table.records = []
      table.loading = true
      let query = prepareQuery(params)
      service
        .index(query)
        .then((response) => {
          table.records = response.data.data
          table.pagination.meta = response.data.meta
          table.pagination.links = response.data.links
          table.loading = false
        })
        .catch((error) => {
          alertStore.error(getResponseError(error))
          table.loading = false
        })
    }

    function getCategories() {
      categoryService
        .index()
        .then((response) => {
          page.categories = response.data.data
        })
        .catch((error) => {
          alertStore.error(getResponseError(error))
          table.loading = false
        })
    }

    watch(mainQuery, (newTableState) => {
      fetchPage(mainQuery)
    })

    onMounted(() => {
      fetchPage(mainQuery)
      getCategories()
    })

    return {
      trans,
      page,
      table,
      onTablePageChange,
      onTableAction,
      onTableSort,
      onPageAction,
      onFiltersClear,
      mainQuery,
      getCategories,
    }
  },
})
</script>

<template>
  <Page
    :title="page.title"
    :breadcrumbs="page.breadcrumbs"
    :actions="page.actions"
    @action="onPageAction"
    class="w-full m-5"
  >
    <template #default>
      <Form id="assign-permissions" @submit.prevent="onSubmit">
        <div>
          <ul
            class="text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          >
            <li
              class="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600"
              v-for="item in form.permissions"
            >
              <div class="flex items-center pl-3">
                <input
                  id="vue-checkbox"
                  type="checkbox"
                  v-model="item.selected"
                  value=""
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                />
                <label
                  for="vue-checkbox"
                  class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  {{ item.title }}
                </label>
              </div>
            </li>
          </ul>
        </div>
      </Form>
    </template>
  </Page>
</template>

<script>
import { trans } from '@/helpers/i18n'
import UserService from '@/services/UserService'
import { watch, onMounted, defineComponent, reactive, ref } from 'vue'
import { getResponseError, prepareQuery } from '@/helpers/api'
import { clearObject, reduceProperties } from '@/helpers/data'
import { toUrl } from '@/helpers/routing'
import { useAlertStore } from '@/stores'
import alertHelpers from '@/helpers/alert'
import Page from '@/views/layouts/Page'
import Table from '@/views/components/Table'
import Avatar from '@/views/components/icons/Avatar'
import Filters from '@/views/components/filters/Filters'
import FiltersRow from '@/views/components/filters/FiltersRow'
import FiltersCol from '@/views/components/filters/FiltersCol'
import TextInput from '@/views/components/input/TextInput'
import Dropdown from '@/views/components/input/Dropdown'

export default defineComponent({
  components: {
    Dropdown,
    TextInput,
    FiltersCol,
    FiltersRow,
    Filters,
    Page,
    Table,
    Avatar,
  },
  setup() {
    const service = new UserService()
    const alertStore = useAlertStore()

    const page = reactive({
      id: 'list_users',
      title: 'Permisos para el usuario común',
      breadcrumbs: [
        {
          name: 'Permisos',
          to: toUrl('/roles'),
          active: true,
        },
      ],

      toggleFilters: false,
      actions: [
        {
          id: 'submit',
          name: trans('global.buttons.save'),
          icon: 'fa fa-save',
          type: 'submit',
        },
      ],
      
    })
    const form = reactive({
      permissions:[]
    })

    function getPermissions() {
      service
        .getPermissions()
        .then((response) => {
          form.permissions = response.data.data
        })
        .catch((error) => {
          alertStore.error(getResponseError(error))
          table.loading = false
        })
    }

    function onPageAction(data) {
      switch (data.action.id) {
        case 'submit':
          onSubmit()
          break
      }
    }

    function onSubmit() {
        console.log("permissions",form);
      service
        .assignPermissions(
          'assign-permissions',
          reduceProperties(form),
        )
        .then(() => {
          //clearObject(form)
        })
      return false
    }

    onMounted(() => {
      getPermissions()
    })

    return {
      trans,
      page,
      form,
      getPermissions,
      onPageAction,
      onSubmit,
    }
  },
})
</script>
